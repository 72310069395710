import {
  Country,
  BusinessArea,
  LineOfBusiness,
  NodeParams,
} from '@riga-claims/atlas-models';

export enum EditorApiMethod {
  GET_MESSAGE_TYPES = 'editor/GetMessageTypes',
  GET_CONNECTION_TYPES = 'editor/GetConnectionTypes',
  GET_NODE_PROPERTIES = 'editor/GetNodeProperties',
}

export enum AuthorizationApiMethod {
  GET_AUTHORIZED_USER = 'auth/getuser',
}

export enum TenantApiMethod {
  TENANTS = 'tenants',
  GET_ALL_FLOWS = 'GetAllFlows',
  FLOWS_METADATA = 'FlowsMetadata',
  MIGRATE = 'MigrateFromOldEditor',
  CHANGES = 'changes',
}

export const getSpecificTenantEndpoint = (tenantId: string): string =>
  `${TenantApiMethod.TENANTS}/${tenantId}`;

export enum FlowApiMethod {
  FLOWS = 'flows',
  CAN_PROMOTE_DEMOTE = 'CanPromoteDemote',
  DEMOTE_FLOWS = 'DemoteFlows',
  GET_DEMOTE_PROMOTE_FLOWS = 'GetDemotePromoteFlows',
  PROMOTE_FLOWS = 'PromoteFlows',
  RENAME_FLOW = 'RenameFlow',
  COPY_FLOW = 'CopyFlow',
  METADATA = 'Metadata',
}

export const getFlowsEndpointForTenant = (tenantId: string): string =>
  `${TenantApiMethod.TENANTS}/${tenantId}/${FlowApiMethod.FLOWS}`;

export const getSpecificFlowEndpoint = (
  tenantId: string,
  fileName: string
): string =>
  `${TenantApiMethod.TENANTS}/${tenantId}/${FlowApiMethod.FLOWS}/${fileName}`;

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ResponseStatus {
  ABORTED = 20,
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVER_ERROR = 500,
  TIMEOUT = 504,
}

export enum AccessRightsApiMethod {
  GET_ALL_TENANTS = 'accessRights/GetAllTenants',
  GET_TENANT_USERS_WITH_ROLES = 'accessRights/GetTenantUsersWithRoles',
  EDIT_USER_ACCESS_TO_TENANT = 'accessRights/EditUserAccessToTenant',
}

export interface DefaultErrorResponse {
  businessLogicError?: string;
}

export interface ApiResponse<T> {
  status: ResponseStatus;
  data: T | null;
  error?: string;
}

/**
 * CanPromoteDemote API response object
 */
export interface PromoteDemoteResponse {
  canPromote: boolean;
  canDemote: boolean;
}

/**
 * GetTenant API response object
 */
export interface FlowTenant {
  id: string;
  businessArea: string;
  country: string;
  lineOfBusiness: string;
  countryId: Country;
  businessAreaId: BusinessArea;
  lineOfBusinessId: LineOfBusiness;
  useAtlasEditor: boolean;
}

/**
 * GetAllFlows API response object
 */
export interface FlowFile {
  fullName: string;
  name: string;
}

//#region  File history  data models/interfaces

/**
 * GetFileHistory API response object
 */
export interface FileHistory {
  fullName: string;
  name: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
}

/**
 * GetHisoryItemDetails/Changes API response object
 */
export interface FlowChanges {
  fileChanges: FileChanges[];
}

export interface FileChanges {
  baseFileName: string;
  addedNodes: FlowNode[];
  removedNodes: FlowNode[];
  addedEdges: FlowEdge[];
  removedEdges: FlowEdge[];
  edgeChanges: PropertyChanges[];
  nodeChanges: PropertyChanges[];
}

export interface ValueBox {
  value: string;
}

export interface Properties {
  excludeFromSummary: ValueBox;
  questionContainsPersonalData: ValueBox;
  answerCanContainPersonalData: ValueBox;
  variableName: ValueBox;
  message: ValueBox;
  validationRegex: ValueBox;
  validationMaxLengthValue: ValueBox;
  validationMaxLengthMessage: ValueBox;
  summaryMessage: ValueBox;
}

export interface CustomProperties {
  prop: string;
}

export interface FlowEdge {
  id: string;
  source: string;
  target: string;
  value: string;
  order: number;
  referenceValue: string;
  connectionType: number;
}
export interface FlowNode {
  id: string;
  x: number;
  y: number;
  messageType: number;
  name: string;
  properties: Properties;
  customProperties: CustomProperties;
}

export interface PropertyChanges {
  object: string;
  property: string;
  newValue: string;
  oldValue: string;
  author?: any;
}

//#endregion

/**
 * GetFlowMetadata API response object
 */
export interface FlowFileMetadata extends FlowFile {
  lastModifiedDate: string;
  lastModifiedBy: string;
  createdBy: string;
}

/**
 * Edge data object from a flow file
 *
 * @interface EdgeParams
 */
export interface EdgeParams {
  id: string;
  source: string;
  target: string;
  value?: string;
  order: number;
  referenceValue?: string;
  connectionType?: number;
}

/**
 * Object consisting of Node and Edge objects
 *
 * @interface FlowGraph {nodes: NodeParams[]; edges: EdgeParams[]}
 */
export interface FlowGraph {
  nodes: NodeParams[];
  edges: EdgeParams[];
  stickyNotes: NodeParams[];
}

/**
 * ReadFlow API response Object consisting of flow and information whether flow can be promoted or demoted
 *
 * @interface ReadFlowResponse {flow: FlowData; canPromoteDemote: CanPromoteDemoteResponse}
 */
export interface ReadFlowResponse {
  flow: FlowData;
  canPromoteDemote: PromoteDemoteResponse;
  lastModifiedDate: string;
}

/**
 * Object consisting of information about graph and tenant
 *
 * @interface FlowData {countryId: number; businessAreaId: number; lineOfBusinessId: number; flowGraphData: FlowGraphData}
 */
export interface FlowData {
  countryId: Country;
  businessAreaId: BusinessArea;
  lineOfBusinessId: LineOfBusiness;
  flowGraphStructure: FlowGraph | null;
}

export interface TenantUserWithRoles {
  email: string;
  canRead: boolean;
  canWrite: boolean;
  canPromote: boolean;
  canViewStatistics: boolean;
  canViewPersonalData: boolean;
}

export interface EditAccessRequestBody extends TenantUserWithRoles {
  tenantId: string;
}

export interface EditUserAccessToTenant {
  success: boolean;
  error: string | null;
}

/**
 * getPromoteDemoteFlows API response Object containing a list of all included files as well as identified loops in the flow
 *
 * @interface PromoteDemoteFileList {countryId: number; businessAreaId: number; lineOfBusinessId: number; flowGraphData: FlowGraphData}
 */
export interface PromoteDemoteFileList {
  fileList: string[];
  loops: FlowLoopItem[][];
}

export interface FlowLoopItem {
  nodeId: string;
  targetFile: string;
}

export interface AuthorizedUser {
  name: string;
  fullName: string;
  email: string;
}
