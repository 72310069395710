import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BusinessArea,
  Country,
  LineOfBusiness,
} from '@riga-claims/atlas-models';
import { BannerType } from '@riga-claims/storybook';

interface BannerInfo {
  message: string;
  type: BannerType;
  permanent?: boolean;
  autoCloseTimeout?: number;
  id: string;
}

interface FlowArea {
  countryId: Country;
  businessAreaId: BusinessArea;
  lineOfBusinessId: LineOfBusiness;
}

export interface GlobalState {
  flowArea: FlowArea | null;
  lastModifiedDate: string | null;
  banners: BannerInfo[];
  hasUnsavedChanges: boolean;
  isSavingChanges: boolean;
  shouldStartSavingChanges: boolean;
  isRestoringFlow: boolean;
  preventUndoRedo: boolean;
}

export const initialGlobalState: GlobalState = {
  flowArea: null,
  lastModifiedDate: null,
  banners: [],
  hasUnsavedChanges: false,
  isSavingChanges: false,
  shouldStartSavingChanges: false,
  isRestoringFlow: false,
  preventUndoRedo: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState,
  reducers: {
    setArea: (state, action: PayloadAction<FlowArea>) => {
      state.flowArea = action.payload;
    },
    setLastModifiedDate: (state, action: PayloadAction<string>) => {
      state.lastModifiedDate = action.payload;
    },
    addBanner: (state, action: PayloadAction<BannerInfo>) => {
      state.banners.push(action.payload);
    },
    removeBanner: (state, action: PayloadAction<string>) => {
      state.banners = state.banners.filter(
        (banner) => banner.id !== action.payload
      );
    },
    setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = action.payload;
    },
    setIsSavingChanges: (state, action: PayloadAction<boolean>) => {
      state.isSavingChanges = action.payload;
    },
    setShouldStartSavingChanges: (state, action: PayloadAction<boolean>) => {
      state.shouldStartSavingChanges = action.payload;
    },
    setIsRestoringFlow: (state, action: PayloadAction<boolean>) => {
      state.isRestoringFlow = action.payload;
    },
    setPreventUndoRedo: (state, action: PayloadAction<boolean>) => {
      state.preventUndoRedo = action.payload;
    },
    resetGlobalState: () => initialGlobalState,
  },
});

export const {
  setArea,
  setLastModifiedDate,
  addBanner,
  removeBanner,
  setHasUnsavedChanges,
  setIsSavingChanges,
  setShouldStartSavingChanges,
  setIsRestoringFlow,
  setPreventUndoRedo,
  resetGlobalState,
} = globalSlice.actions;

export default globalSlice.reducer;
