import {
  BaseNodeProperty,
  Denmark_Motor_GetVehiclesNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class DenmarkGetUserVehiclesLayout extends BaseLayout {
  constructor(node: Denmark_Motor_GetVehiclesNode) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const { userInformationData } = NodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.inputValidation).addTextField(
      {
        fieldName: userInformationData,
        label: 'User information',
        placeholder: 'User information here',
        ...properties[userInformationData],
      },
      this.mappedFields
    );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default DenmarkGetUserVehiclesLayout;
