import { EditorConfiguration, EditorConfigurationKeys } from './types';

const atestConfig: EditorConfiguration = {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]:
    '3d33d8b6-08be-4829-b92c-0acda5d320d6',
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]:
    '5c6669d3eb049c2df965b93b',
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]:
    '340C2383-1158-4DC6-BD80-1394A3BD6FCB',
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: 'Atlas Editor UI',
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: 'ATEST',
};

export default atestConfig;
