import { FunctionComponent } from 'react';
import type { Graph } from '@antv/g6';

import {
  AccessDataType,
  AccessLogData,
  AccessLogDataItem,
  AccessType,
  NodeParams,
} from '@riga-claims/atlas-models';
import { DropdownSelect } from '@riga-claims/storybook';
import { ButtonText } from '@ids-react/button';

import { getEnumValues } from '@/utils';
import { DebouncedUpdateFlowGraphStore } from '@/hooks';

import helperClasses from '@/styles/helperClasses.module.scss';
import classes from './AccessedDataList.module.scss';

export const LABELS = {
  addData: 'Add data',
};

export interface GetRemainingAccessDataTypesParams {
  accessLogData: AccessLogData;
  accessType: AccessType;
}

export const getRemainingAccessDataTypes = ({
  accessLogData,
  accessType,
}: GetRemainingAccessDataTypesParams) =>
  getEnumValues(AccessDataType).filter(
    (accessDataType) =>
      accessDataType !== AccessDataType._Uncategorized &&
      !accessLogData.some(
        (accessLogDataItem) =>
          accessLogDataItem.accessDataType === accessDataType &&
          accessLogDataItem.accessType === accessType
      )
  );

export interface AccessedDataListProps {
  graph: Graph;
  selectedNodeParams: NodeParams;
  debouncedUpdateFlowGraphStore: DebouncedUpdateFlowGraphStore;
  accessLogData: AccessLogData;
  dataSubject: string;
  accessType: AccessType;
  title: string;
}

const AccessedDataList: FunctionComponent<AccessedDataListProps> = ({
  graph,
  selectedNodeParams,
  debouncedUpdateFlowGraphStore,
  accessLogData,
  dataSubject,
  accessType,
  title,
}: AccessedDataListProps) => {
  const remainingAccessDataTypes = getRemainingAccessDataTypes({
    accessLogData,
    accessType,
  });

  const handleAddAccessDataType = () => {
    const newAccessLogDataItem: AccessLogDataItem = {
      dataSubject,
      accessType,
      accessDataType: remainingAccessDataTypes[0],
    };

    graph.updateItem(selectedNodeParams.id, {
      accessLogData: [...accessLogData, newAccessLogDataItem],
    });

    debouncedUpdateFlowGraphStore({ updateInstantly: true });
  };

  const handleRemoveAccessDataType = (
    removedAccessDataType: AccessDataType
  ) => {
    const newAccessLogData: AccessLogData = [...accessLogData].filter(
      (accessLogDataItem) =>
        !(
          accessLogDataItem.accessDataType === removedAccessDataType &&
          accessLogDataItem.accessType === accessType
        )
    );

    graph.updateItem(selectedNodeParams.id, {
      accessLogData: newAccessLogData,
    });

    debouncedUpdateFlowGraphStore({ updateInstantly: true });
  };

  const replaceAccessDataType = (
    previousAccessDataType: AccessDataType,
    newAccessDataType: AccessDataType
  ) => {
    const newAccessLogData: AccessLogData = accessLogData.map(
      (accessLogDataItem) =>
        accessLogDataItem.accessDataType === previousAccessDataType &&
        accessLogDataItem.accessType === accessType
          ? { ...accessLogDataItem, accessDataType: newAccessDataType }
          : accessLogDataItem
    );

    graph.updateItem(selectedNodeParams.id, {
      accessLogData: newAccessLogData,
    });

    debouncedUpdateFlowGraphStore({ updateInstantly: true });
  };

  return (
    <div className={classes.AccessedDataList}>
      <span className={classes.Title}>{title}</span>

      {accessLogData
        .filter(
          (accessLogDataItem) => accessLogDataItem.accessType === accessType
        )
        .map((accessLogDataItem) => (
          <div
            className={classes.AccessedData}
            key={accessLogDataItem.accessDataType}
          >
            <div className={classes.AccessDataType}>
              <DropdownSelect
                id="access-data-type"
                name="access-data-type"
                value={accessLogDataItem.accessDataType.toString()}
                data={{
                  options: [
                    ...remainingAccessDataTypes,
                    accessLogDataItem.accessDataType,
                  ].map((value) => ({
                    label: AccessDataType[value],
                    value: value.toString(),
                  })),
                }}
                onChange={(event) =>
                  replaceAccessDataType(
                    accessLogDataItem.accessDataType,
                    parseInt(event.target.value)
                  )
                }
                compact
                autocomplete={undefined}
              />
            </div>
            <div className={classes.RemoveData}>
              <ButtonText
                className={helperClasses.CompactActionButton}
                onClick={() =>
                  handleRemoveAccessDataType(accessLogDataItem.accessDataType)
                }
              >
                <span className={classes.RemoveDataIcon} />
              </ButtonText>
            </div>
          </div>
        ))}

      <div className={classes.AddData}>
        <ButtonText
          className={helperClasses.CompactActionButton}
          onClick={handleAddAccessDataType}
          disabled={!remainingAccessDataTypes.length}
        >
          <span className={classes.AddButtonIcon} />
          <span className={classes.AddButtonLabel}>{LABELS.addData}</span>
        </ButtonText>
      </div>
    </div>
  );
};

export default AccessedDataList;
