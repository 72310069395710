import type { EdgeConfig, GraphData, NodeConfig } from '@antv/g6';
import { MessageType, NodeParams } from '@riga-claims/atlas-models';

import type { EdgeParams, FlowGraph } from '@/api/types';
import { MultiEdgeProperty } from '@/G6/customEdges/edgeTypes';

/**
 * Maps the G6 GraphData to the FlowData ReadFlow API expects
 *
 * @param {GraphData} graphData The G6 GraphData object received from G6.save()
 * @return {*} {FlowData} The created FlowData object
 */
const mapG6GraphDataToFlowGraph = (graphData: GraphData): FlowGraph => {
  return {
    nodes:
      graphData.nodes?.filter(isTreeNode).map((_node) => {
        const node = _node as NodeConfig & NodeParams;
        return {
          x: Math.floor(node.x || 0),
          y: Math.floor(node.y || 0),
          id: node.id,
          messageType: node.messageType,
          name: node.name,
          accessLogData: node.accessLogData,
          properties: node.properties,
          customProperties: node.customProperties,
        };
      }) || [],
    edges:
      graphData.edges?.map((_edge) => {
        const edge = _edge as EdgeConfig & EdgeParams;
        return {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          value: edge.value,
          order: edge.order,
          referenceValue: edge.referenceValue,
          connectionType: edge.connectionType,
          // Following properties required only for proper rendering in G6 canvas
          type: edge.type,
          [MultiEdgeProperty.UseQuadraticCurve]:
            edge[MultiEdgeProperty.UseQuadraticCurve],
          [MultiEdgeProperty.OffsetCounter]:
            edge[MultiEdgeProperty.OffsetCounter],
          [MultiEdgeProperty.ArbitraryDirection]:
            edge[MultiEdgeProperty.ArbitraryDirection],
          [MultiEdgeProperty.LabelOffsetOscillator]:
            edge[MultiEdgeProperty.LabelOffsetOscillator],
        };
      }) || [],
    stickyNotes:
      graphData.nodes?.filter(isStickyNote).map((_node) => {
        const node = _node as NodeConfig & NodeParams;
        return {
          x: Math.floor(node.x || 0),
          y: Math.floor(node.y || 0),
          id: node.id,
          messageType: node.messageType,
          name: node.name,
          accessLogData: node.accessLogData,
          properties: node.properties,
          customProperties: node.customProperties,
        };
      }) || [],
  };
};

const isStickyNote = (node: NodeConfig): boolean =>
  node.messageType === MessageType.StickyNote;
const isTreeNode = (node: NodeConfig): boolean => !isStickyNote(node);

export default mapG6GraphDataToFlowGraph;
