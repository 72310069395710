import {
  BaseNodeProperty,
  InputTextNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import InputValidationRadioGroup from '@/components/InputValidationRadioGroup/InputValidationRadioGroup';

import BaseLayout from './BaseLayout';
import InputSizeRadioGroup from '@/components/InputSizeRadioGroup/InputSizeRadioGroup';

class InputTextLayout extends BaseLayout {
  constructor(node: InputTextNode) {
    super(node);

    const { properties } = node;
    const { placeholder, removeWhiteSpaces, toUpperCase, inputSize } =
      NodeProperty;
    const {
      variableName,
      message,
      validationRegex,
      validationMessage,
      validationMaxLengthValue,
    } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: placeholder,
          label: nodeProperties[placeholder].label,
          placeholder: nodeProperties[placeholder].placeholder,
          ...properties[placeholder],
        },
        this.mappedFields
      )
      .addJSXElementField(
        {
          fieldName: inputSize,
          renderElement: InputSizeRadioGroup,
          ...properties[inputSize],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addJSXElementField(
        {
          fieldName: validationRegex,
          renderElement: InputValidationRadioGroup,
          ...properties[validationRegex],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: baseNodeProperties[validationMessage].label,
          placeholder: baseNodeProperties[validationMessage].placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: validationMaxLengthValue,
          label: baseNodeProperties[validationMaxLengthValue].label,
          placeholder: baseNodeProperties[validationMaxLengthValue].placeholder,
          ...properties[validationMaxLengthValue],
        },
        this.mappedFields
      );

    this.propertiesTab
      .addGroup(nodePropertiesGroups.editAnswers, true)
      .addCheckboxField(
        {
          fieldName: removeWhiteSpaces,
          label: nodeProperties[removeWhiteSpaces].label,
          ...properties[removeWhiteSpaces],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: toUpperCase,
          label: nodeProperties[toUpperCase].label,
          ...properties[toUpperCase],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputTextLayout;
