import { BusinessArea } from '@riga-claims/atlas-models';

const businessAreas = {
  [BusinessArea.Common]: '',
  [BusinessArea.Private]: 'Private',
  [BusinessArea.Commercial]: 'Commercial',
  [BusinessArea.Practice]: 'Practice',
  [BusinessArea.Test]: 'Test',
};

export default businessAreas;
