import { Graph } from '@antv/g6';
import { useCallback } from 'react';

import { setFlowGraph, setHasUnsavedChanges } from '@/store';
import { debounce, mapG6GraphDataToFlowGraph } from '@/utils';
import { ON_CHANGE_UPDATE_DELAY } from '@/constants';

import useAppDispatch from './useAppDispatch';

export type UpdateFlowGraphStore = (dontSetHasUnsavedChanges?: boolean) => void;
export type DebouncedUpdateFlowGraphStore = (params?: {
  dontSetHasUnsavedChanges?: boolean;
  updateInstantly?: boolean;
}) => void;

const useUpdateFlowGraphStore = (
  g6Graph: Graph | null
): {
  updateFlowGraphStore: UpdateFlowGraphStore;
  debouncedUpdateFlowGraphStore: DebouncedUpdateFlowGraphStore;
} => {
  const dispatch = useAppDispatch();

  const updateFlowGraphStore: UpdateFlowGraphStore = (
    dontSetHasUnsavedChanges
  ) => {
    if (g6Graph) {
      dispatch(setFlowGraph(mapG6GraphDataToFlowGraph(g6Graph.save())));
      if (!dontSetHasUnsavedChanges) {
        dispatch(setHasUnsavedChanges(true));
      }
    }
  };

  const _debouncedUpdateFlowGraphStore = debounce(
    updateFlowGraphStore,
    ON_CHANGE_UPDATE_DELAY
  );

  const debouncedUpdateFlowGraphStore = useCallback(
    (params?: {
      dontSetHasUnsavedChanges?: boolean;
      updateInstantly?: boolean;
    }) => {
      if (params?.updateInstantly) {
        updateFlowGraphStore(params?.dontSetHasUnsavedChanges);
      } else {
        _debouncedUpdateFlowGraphStore(params?.dontSetHasUnsavedChanges);
      }
    },
    []
  );

  return { updateFlowGraphStore, debouncedUpdateFlowGraphStore };
};

export default useUpdateFlowGraphStore;
