import {
  BaseNodeProperty,
  NodeProperty,
  FileUpload_AttachmentViewerNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class FileUploadAttachmentViewerLayout extends BaseLayout {
  constructor(node: FileUpload_AttachmentViewerNode) {
    super(node);

    const { properties } = node;
    const { message, variableName } = BaseNodeProperty;

    const {
      accessId,
      contentOwner,
      country,
      customerType,
      lineOfBusiness,
      policyType,
      uploadDuringSubmission,
      aclName,
      claimNumberRegex,
      allowedFileExtensions,
      maxFileSizeInBytes,
      fileSizeErrorMessage,
      fileEmptyErrorMessage,
      fileTypeErrorMessage,
      uploadFailedErrorMessage,
      maxFileCount,
      fileCountErrorMessage,
      uploadFilesButtonText,
      uploadInProgressButtonText,
      browseFilesButtonText,
      dropZoneContainerText,
      headerText,
    } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.settings)
      .addCheckboxField(
        {
          fieldName: uploadDuringSubmission,
          label: nodeProperties.uploadDuringSubmission.label,
          ...properties[uploadDuringSubmission],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accessId,
          label: nodeProperties.accessId.label,
          placeholder: nodeProperties.accessId.placeholder,
          ...properties[accessId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: contentOwner,
          label: nodeProperties.contentOwner.label,
          placeholder: nodeProperties.contentOwner.placeholder,
          ...properties[contentOwner],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: country,
          label: nodeProperties.country.label,
          placeholder: nodeProperties.country.placeholder,
          ...properties[country],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: customerType,
          label: nodeProperties.customerType.label,
          placeholder: nodeProperties.customerType.placeholder,
          ...properties[customerType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: lineOfBusiness,
          label: nodeProperties.lineOfBusiness.label,
          placeholder: nodeProperties.lineOfBusiness.placeholder,
          ...properties[lineOfBusiness],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: policyType,
          label: nodeProperties.policyType.label,
          placeholder: nodeProperties.policyType.placeholder,
          ...properties[policyType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: aclName,
          label: nodeProperties.aclName.label,
          placeholder: nodeProperties.aclName.placeholder,
          ...properties[aclName],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: uploadFilesButtonText,
          label: nodeProperties.uploadFilesButtonText.label,
          placeholder: nodeProperties.uploadFilesButtonText.placeholder,
          ...properties[uploadFilesButtonText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: uploadInProgressButtonText,
          label: nodeProperties.uploadInProgressButtonText.label,
          placeholder: nodeProperties.uploadInProgressButtonText.placeholder,
          ...properties[uploadInProgressButtonText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: browseFilesButtonText,
          label: nodeProperties.browseFilesButtonText.label,
          placeholder: nodeProperties.browseFilesButtonText.placeholder,
          ...properties[browseFilesButtonText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: dropZoneContainerText,
          label: nodeProperties.dropZoneContainerText.label,
          placeholder: nodeProperties.dropZoneContainerText.placeholder,
          ...properties[dropZoneContainerText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: headerText,
          label: nodeProperties.headerText.label,
          placeholder: nodeProperties.headerText.placeholder,
          ...properties[headerText],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.fileValidation)
      .addTextField(
        {
          fieldName: claimNumberRegex,
          label: nodeProperties.claimNumberRegex.label,
          placeholder: nodeProperties.claimNumberRegex.placeholder,
          ...properties[claimNumberRegex],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: allowedFileExtensions,
          label: nodeProperties.allowedFileExtensions.label,
          placeholder: nodeProperties.allowedFileExtensions.placeholder,
          ...properties[allowedFileExtensions],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: maxFileSizeInBytes,
          label: nodeProperties.maxFileSizeInBytes.label,
          placeholder: nodeProperties.maxFileSizeInBytes.placeholder,
          ...properties[maxFileSizeInBytes],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: maxFileCount,
          label: nodeProperties.maxFileCount.label,
          placeholder: nodeProperties.maxFileCount.placeholder,
          ...properties[maxFileCount],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: fileSizeErrorMessage,
          label: nodeProperties.fileSizeErrorMessage.label,
          placeholder: nodeProperties.fileSizeErrorMessage.placeholder,
          ...properties[fileSizeErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: fileEmptyErrorMessage,
          label: nodeProperties.fileEmptyErrorMessage.label,
          placeholder: nodeProperties.fileEmptyErrorMessage.placeholder,
          ...properties[fileEmptyErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: fileTypeErrorMessage,
          label: nodeProperties.fileTypeErrorMessage.label,
          placeholder: nodeProperties.fileTypeErrorMessage.placeholder,
          ...properties[fileTypeErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: uploadFailedErrorMessage,
          label: nodeProperties.uploadFailedErrorMessage.label,
          placeholder: nodeProperties.uploadFailedErrorMessage.placeholder,
          ...properties[uploadFailedErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: fileCountErrorMessage,
          label: nodeProperties.fileCountErrorMessage.label,
          placeholder: nodeProperties.fileCountErrorMessage.placeholder,
          ...properties[fileCountErrorMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FileUploadAttachmentViewerLayout;
