import { Graph } from '@antv/g6';

export interface G6Namespace {
  graph: Graph | null;
}

export interface G6Globals {
  g6: G6Namespace;
}

export enum G6Keys {
  OFFSET_ANGLE_KEY = 'OFFSET_ANGLE_KEY',
  ITEM_SIZE = 'ITEM_SIZE',
  IS_DRAWING_EDGE = 'IS_DRAWING_EDGE',
  BLOCK_CONTEXT_MENU_ONCE = 'BLOCK_CONTEXT_MENU_ONCE',
}

export enum ItemSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum G6NodeStates {
  DEFAULT = 'default',
  SELECTED = 'selected',
  MULTIPLE_SELECTED = 'multipleSelected',
  HOVER = 'hover',
  DRAW_EDGE = 'drawEdge',
  HOVER_WITH_EDGE = 'hoverWithEdge',
  MARKED = 'marked',
  MARKED_HIGHLIGHTED = 'markedHighlighted',
  ERROR = 'error',
  // Following states should be the same as The ItemSize enum
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum G6EdgeStates {
  DEFAULT = 'default',
  SELECTED = 'selected',
  HOVER = 'hover',
  MARKED = 'marked',
  MARKED_HIGHLIGHTED = 'markedHighlighted',
  // Following states should be the same as The ItemSize enum
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum G6ItemTypes {
  NODE = 'node',
  EDGE = 'edge',
}

export enum G6Styles {
  X = 'x',
  Y = 'y',
  R = 'r',
  RADIUS = 'radius',
  WIDTH = 'width',
  HEIGHT = 'height',
  OFFSET = 'offset',
  STROKE = 'stroke',
  STROKE_OPACITY = 'strokeOpacity',
  FILL = 'fill',
  FILL_OPACITY = 'fillOpacity',
  LINE_WIDTH = 'lineWidth',
  LINE_APPEND_WIDTH = 'lineAppendWidth',
  LINE_DASH = 'lineDash',
  PATH = 'path',
  POINTS = 'points',
  MATRIX = 'matrix',
  OPACITY = 'opacity',
  SIZE = 'size',
  END_ARROW = 'endArrow',
  START_ARROW = 'startArrow',
  SHADOW_COLOR = 'shadowColor',
  SHADOW_BLUR = 'shadowBlur',
  SHADOW_OFFSET_X = 'shadowOffsetX',
  SHADOW_OFFSET_Y = 'shadowOffsetY',
  CURSOR = 'cursor',
  POSITION = 'position',
  FONTSIZE = 'fontsize',
  MARGIN_TOP = 'marginTop',
  MARGIN_BOTTOM = 'marginBottom',
  MARGIN_LEFT = 'marginLeft',
  MARGIN_RIGHT = 'marginRight',
  TEXT_BASELINE = 'textBaseline',
  FONT_SIZE = 'fontSize',
  FONT_WEIGHT = 'fontWeight',
  FONT_FAMILY = 'fontFamily',
  LINE_HEIGHT = 'lineHeight',
  NEXT = 'next',
  TEXT = 'text',
  ICON = 'text',
}

//https://g6.antv.vision/en/docs/api/Event
export enum G6Events {
  CLICK = 'click',
  DBLCLICK = 'dblclick',
  MOUSEDOWN = 'mousedown',
  MOUDEUP = 'mouseup',
  CONTEXTMENU = 'contextmenu',
  MOUSEENTER = 'mouseenter',
  MOUSEOUT = 'mouseout',
  MOUSEOVER = 'mouseover',
  MOUSEMOVE = 'mousemove',
  MOUSELEAVE = 'mouseleave',
  DRAGSTART = 'dragstart',
  DRAGEND = 'dragend',
  DRAG = 'drag',
  DRAGENTER = 'dragenter',
  DRAGLEAVE = 'dragleave',
  DRAGOVER = 'dragover',
  DRAGOUT = 'dragout',
  DDROP = 'drop',
  KEYUP = 'keyup',
  KEYDOWN = 'keydown',
  WHEEL = 'wheel',
  FOCUS = 'focus',
  BLUR = 'blur',
  TOUCHSTART = 'touchstart',
  TOUCHMOVE = 'touchmove',
  TOUCHEND = 'touchend',
  CANVAS_TOUCHSTART = 'canvas:touchstart',
  CANVAS_TOUCHMOVE = 'canvas:touchmove',
  CANVAS_TOUCHEND = 'canvas:touchend',
  NODE_TOUCHSTART = 'node:touchstart',
  NODE_TOUCHMOVE = 'node:touchmove',
  NODE_TOUCHEND = 'node:touchend',
  COMBO_TOUCHSTART = 'combo:touchstart',
  COMBO_TOUCHMOVE = 'combo:touchmove',
  COMBO_TOUCHEND = 'combo:touchend',
  EDGE_TOUCHSTART = 'edge:touchstart',
  EDGE_TOUCHMOVE = 'edge:touchmove',
  EDGE_TOUCHEND = 'edge:touchend',
  NODE_CONTEXTMENU = 'node:contextmenu',
  NODE_CLICK = 'node:click',
  NODE_DBLCLICK = 'node:dblclick',
  NODE_MOUSEDOWN = 'node:mousedown',
  NODE_MOUSEUP = 'node:mouseup',
  NODE_MOUSEENTER = 'node:mouseenter',
  NODE_MOUSELEAVE = 'node:mouseleave',
  NODE_MOUSEMOVE = 'node:mousemove',
  NODE_MOUSEOUT = 'node:mouseout',
  NODE_MOUSEOVER = 'node:mouseover',
  NODE_DROP = 'node:drop',
  NODE_DRAGOVER = 'node:dragover',
  NODE_DRAGENTER = 'node:dragenter',
  NODE_DRAGLEAVE = 'node:dragleave',
  NODE_DRAGSTART = 'node:dragstart',
  NODE_DRAG = 'node:drag',
  NODE_DRAGEND = 'node:dragend',
  NODE_TAP = 'node:tap',
  NODE_PANSTART = 'node:panstart',
  NODE_PANMOVE = 'node:panmove',
  NODE_PANEND = 'node:panend',
  COMBO_CONTEXTMENU = 'combo:contextmenu',
  COMBO_CLICK = 'combo:click',
  COMBO_DBLCLICK = 'combo:dblclick',
  COMBO_MOUSEDOWN = 'combo:mousedown',
  COMBO_MOUSEUP = 'combo:mouseup',
  COMBO_MOUSEENTER = 'combo:mouseenter',
  COMBO_MOUSELEAVE = 'combo:mouseleave',
  COMBO_MOUSEMOVE = 'combo:mousemove',
  COMBO_MOUSEOUT = 'combo:mouseout',
  COMBO_MOUSEOVER = 'combo:mouseover',
  COMBO_DROP = 'combo:drop',
  COMBO_DRAGOVER = 'combo:dragover',
  COMBO_DRAGENTER = 'combo:dragenter',
  COMBO_DRAGLEAVE = 'combo:dragleave',
  COMBO_DRAGSTART = 'combo:dragstart',
  COMBO_DRAG = 'combo:drag',
  COMBO_DRAGEND = 'combo:dragend',
  COMBO_TAP = 'combo:tap',
  COMBO_PANSTART = 'combo:panstart',
  COMBO_PANMOVE = 'combo:panmove',
  COMBO_PANEND = 'combo:panend',
  EDGE_CONTEXTMENU = 'edge:contextmenu',
  EDGE_CLICK = 'edge:click',
  EDGE_DBLCLICK = 'edge:dblclick',
  EDGE_MOUSEDOWN = 'edge:mousedown',
  EDGE_MOUSEUP = 'edge:mouseup',
  EDGE_MOUSEENTER = 'edge:mouseenter',
  EDGE_MOUSELEAVE = 'edge:mouseleave',
  EDGE_MOUSEMOVE = 'edge:mousemove',
  EDGE_MOUSEOUT = 'edge:mouseout',
  EDGE_MOUSEOVER = 'edge:mouseover',
  EDGE_DROP = 'edge:drop',
  EDGE_DRAGOVER = 'edge:dragover',
  EDGE_DRAGENTER = 'edge:dragenter',
  EDGE_DRAGLEAVE = 'edge:dragleave',
  CANVAS_CONTEXTMENU = 'canvas:contextmenu',
  CANVAS_CLICK = 'canvas:click',
  CANVAS_DBLCLICK = 'canvas:dblclick',
  CANVAS_MOUSEDOWN = 'canvas:mousedown',
  CANVAS_MOUSEUP = 'canvas:mouseup',
  CANVAS_MOUSEENTER = 'canvas:mouseenter',
  CANVAS_MOUSELEAVE = 'canvas:mouseleave',
  CANVAS_MOUSEMOVE = 'canvas:mousemove',
  CANVAS_MOUSEOUT = 'canvas:mouseout',
  CANVAS_MOUSEOVER = 'canvas:mouseover',
  CANVAS_DROP = 'canvas:drop',
  CANVAS_DRAGENTER = 'canvas:dragenter',
  CANVAS_DRAGLEAVE = 'canvas:dragleave',
  CANVAS_DRAGSTART = 'canvas:dragstart',
  CANVAS_DRAG = 'canvas:drag',
  CANVAS_DRAGEND = 'canvas:dragend',
  CANVAS_TAP = 'canvas:tap',
  CANVAS_PANSTART = 'canvas:panstart',
  CANVAS_PANMOVE = 'canvas:panmove',
  CANVAS_PANEND = 'canvas:panend',
  BEFORERENDER = 'beforerender',
  AFTERRENDER = 'afterrender',
  BEFOREADDITEM = 'beforeadditem',
  AFTERADDITEM = 'afteradditem',
  BEFOREREMOVEITEM = 'beforeremoveitem',
  AFTERREMOVEITEM = 'afterremoveitem',
  BEFOREUPDATEITEM = 'beforeupdateitem',
  AFTERUPDATEITEM = 'afterupdateitem',
  BEFOREITEMVISIBILITYCHANGE = 'beforeitemvisibilitychange',
  AFTERITEMVISIBILITYCHANGE = 'afteritemvisibilitychange',
  BEFOREITEMSTATECHANGE = 'beforeitemstatechange',
  AFTERITEMSTATECHANGE = 'afteritemstatechange',
  BEFOREITEMREFRESH = 'beforeitemrefresh',
  AFTERITEMREFRESH = 'afteritemrefresh',
  BEFOREITEMSTATESCLEAR = 'beforeitemstatesclear',
  AFTERITEMSTATESCLEAR = 'afteritemstatesclear',
  BEFOREMODECHANGE = 'beforemodechange',
  AFTERMODECHANGE = 'aftermodechange',
  BEFORELAYOUT = 'beforelayout',
  AFTERLAYOUT = 'afterlayout',
  BEFORECREATEEDGE = 'beforecreateedge',
  AFTERCREATEEDGE = 'aftercreateedge',
  BEFOREGRAPHREFRESHPOSITION = 'beforegraphrefreshposition',
  AFTERGRAPHREFRESHPOSITION = 'aftergraphrefreshposition',
  BEFOREGRAPHREFRESH = 'beforegraphrefresh',
  AFTERGRAPHREFRESH = 'aftergraphrefresh',
  BEFOREANIMATE = 'beforeanimate',
  AFTERANIMATE = 'afteranimate',
  BEFOREPAINT = 'beforepaint',
  AFTERPAINT = 'afterpaint',
  BEFORECOLLAPSEEXPANDCOMBO = 'beforecollapseexpandcombo',
  AFTERCOLLAPSEEXPANDCOMBO = 'aftercollapseexpandcombo',
  GRAPHSTATECHANGE = 'graphstatechange',
  AFTERACTIVATERELATIONS = 'afteractivaterelations',
  NODESELECTCHANGE = 'nodeselectchange',
  TOOLTIPCHANGE = 'tooltipchange',
  WHEELZOOM = 'wheelzoom',
  VIEWPORTCHANGE = 'viewportchange',
  DRAGNODEEND = 'dragnodeend',
  STACKCHANGE = 'stackchange',
  TAP = 'tap',
  PINCHSTART = 'pinchstart',
  PINCHMOVE = 'pinchmove',
  PANSTART = 'panstart',
  PANMOVE = 'panmove',
  PANEND = 'panend',
}

export enum G6CustomBehaviors {
  CREATE_EDGE = 'CREATE_EDGE',
  CREATE_NODE_MODE = 'CREATE_NODE_MODE',
}
