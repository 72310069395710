import {
  MessageType,
  NodeProperty,
  BaseNodeProperty,
  Denmark_AuthenticationNode,
  Finland_AuthenticationNode,
  Norway_AuthenticationNode,
  Sweden_AuthenticationNode,
  EmployeeAuthenticationNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class AuthenticationLayout extends BaseLayout {
  constructor(
    node:
      | Denmark_AuthenticationNode
      | Finland_AuthenticationNode
      | Norway_AuthenticationNode
      | Sweden_AuthenticationNode
      | EmployeeAuthenticationNode
  ) {
    super(node);

    const { properties } = node;
    const { loginButton, loginMobileButton } = NodeProperty;
    const { message, variableName, validationMessage } = BaseNodeProperty;

    const customerFacingGroup = this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: loginButton,
          label: nodeProperties[loginButton].label,
          placeholder: nodeProperties[loginButton].placeholder,
          ...properties[loginButton],
        },
        this.mappedFields
      );

    if (
      node.messageType === MessageType.Sweden_Authentication
    ) {
      customerFacingGroup.addTextField(
        {
          fieldName: loginMobileButton,
          label: nodeProperties[loginMobileButton].label,
          placeholder: nodeProperties[loginMobileButton].placeholder,
          ...(node as Sweden_AuthenticationNode)
            .properties[loginMobileButton],
        },
        this.mappedFields
      );
    }

    this.validationTab
      .addGroup(nodePropertiesGroups.authenticationFailed)
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: nodeProperties.authenticationErrorMessage.label,
          placeholder: nodeProperties.authenticationErrorMessage.placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default AuthenticationLayout;
