import {
  NodeProperty,
  BaseNodeProperty,
  Sweden_FollowUp_DecryptNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class DecryptLayout extends BaseLayout {
  constructor(node: Sweden_FollowUp_DecryptNode) {
    super(node);

    const { properties } = node;
    const { encryptedValue } = NodeProperty;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.decryptOptions).addTextField(
      {
        fieldName: encryptedValue,
        label: nodeProperties[encryptedValue].label,
        placeholder: nodeProperties[encryptedValue].placeholder,
        ...properties[encryptedValue],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default DecryptLayout;
