import {
  NodeProperty,
  BaseNodeProperty,
  Finland_WorkersCompensation_ShowOccupationalClassesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FinlandWorkersCompShowOccupationalClassesLayout extends BaseLayout {
  constructor(node: Finland_WorkersCompensation_ShowOccupationalClassesNode) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData, message } =
      BaseNodeProperty;
    const { occupationalClasses } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: occupationalClasses,
          label: 'Occupational classes',
          placeholder:
            'Occupational classes object from get occupational classes node',
          ...node.properties[occupationalClasses],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandWorkersCompShowOccupationalClassesLayout;
