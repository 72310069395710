import {
  BaseNodeProperty,
  Norway_Motor_GetVehiclesNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class NorwayGetUserVehiclesLayout extends BaseLayout {
  constructor(node: Norway_Motor_GetVehiclesNode) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const { customerNumber } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.propertiesTab.addGroup().addTextField(
      {
        fieldName: customerNumber,
        label: nodeProperties[customerNumber].label,
        placeholder: nodeProperties[customerNumber].placeholder,
        ...node.properties[customerNumber],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default NorwayGetUserVehiclesLayout;
