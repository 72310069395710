import {
  BaseNodeProperty,
  NodeProperty,
  InputChoiceFromCarouselNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';
class InputChoiceFromCarouselLayout extends BaseLayout {
  constructor(node: InputChoiceFromCarouselNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;

    const { carouselNodeName, listItemDisplayPattern } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: carouselNodeName,
          label: nodeProperties[carouselNodeName].label,
          placeholder: nodeProperties[carouselNodeName].placeholder,
          ...properties[carouselNodeName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: listItemDisplayPattern,
          label: nodeProperties[listItemDisplayPattern].label,
          placeholder: nodeProperties[listItemDisplayPattern].placeholder,
          ...properties[listItemDisplayPattern],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputChoiceFromCarouselLayout;
