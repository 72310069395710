export enum UserEventKeys {
  ENTER = '{enter}',
  SPACE = '{space}',
  ESC = '{esc}',
  BACKSPACE = '{backspace}',
  DEL = '{del}',
  ARROW_UP = '{arrowup}',
  ARROW_DOWN = '{arrowdown}',
  SELECT_ALL = '{selectall}',
  SHIFT_KEY_DOWN = '{shift}',
  SHIFT_KEY_UP = '{/shift}',
  CTRL_KEY_DOWN = '{ctrl}',
  CTRL_KEY_UP = '{/ctrl}',
  ALT_KEY_DOWN = '{alt}',
  ALT_KEY_UP = '{/alt}',
  META_KEY_DOWN = '{meta}',
  META_KEY_UP = '{/meta}',
}

export enum TestIds {
  G6_CANVAS = 'G6_CANVAS',
  CONTEXT_MENU = 'CONTEXT_MENU',
  CREATE_NODE = 'CREATE_NODE',
  DELETE_NODE = 'DELETE_NODE',
  SUMMARY_EDITOR = 'SUMMARY_EDITOR',
  NODE_TYPE_ICON = 'NODE_TYPE_ICON',
  COPY_NODES = 'COPY_NODES',
  PASTE_NODES = 'PASTE_NODES',
  CREATE_NODE_IN_CONNECTION = 'CREATE_NODE_IN_CONNECTION',
  PROPERTY_TAB = 'PROPERTY_TAB',
  SIDE_PANEL = 'SIDE_PANEL',
  SIDE_PANEL_MASK = 'SIDE_PANEL_MASK',
  REMOVE_NODE_FROM_CONNECTION = 'REMOVE_NODE_FROM_CONNECTION',
  DELETE_NODE_FROM_CONNECTION = 'DELETE_NODE_FROM_CONNECTION',
}
