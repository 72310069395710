import { ChangeEvent } from 'react';
import { 
  InputLabel,
  InputLabelWrapper
} from '@ids-react/common';
import { JSXElementRenderProps } from '@/nodeVisualConfigurations';
import classes from './InputSizeRadioGroup.module.scss';
import { InputSize } from '@riga-claims/tellus-models';

interface InputSizeLabel {
  label: string;
  id: string;
}

export const InputSizeConfig: Record<string, InputSizeLabel> = {
  [InputSize.Small]: {
    label: 'Small',
    id: 'inputSize_small',
  },
  [InputSize.Medium]: {
    label: 'Medium (Default)',
    id: 'inputSize_medium',
  },
  [InputSize.Large]: {
    label: 'Large',
    id: 'inputSize_large',
  },
};

const InputSizeRadioGroup = ({
  debouncedUpdateFlowGraphStore,
  graph,
  fieldData,
  selectedNodeParams,
}: JSXElementRenderProps): JSX.Element => {

  const currentInputSize = selectedNodeParams.properties.inputSize?.value || '';
  const possibleValues = fieldData.possibleValues || [];

  const handleRadioButtonClick = (event: ChangeEvent<HTMLInputElement>) => {
      graph.updateItem(selectedNodeParams.id, {
        properties: {
          inputSize: { value: event.target.value },
        },
    });

    debouncedUpdateFlowGraphStore({ updateInstantly: true });
  };
 
  return (
    <div>
      <div>
        <InputLabelWrapper className={classes.InputLabelWrapper} >
          <InputLabel inputId="inputSize">
            Input size
          </InputLabel>
        </InputLabelWrapper>
      </div>
      <div className={classes.RadioGroup}>
      {possibleValues && possibleValues.sort().reverse().map((allowedSize: string) => (
        <div key={allowedSize}>
          <input
            type="radio"
            id={InputSizeConfig[allowedSize].id}
            name="inputSize"
            value={allowedSize}
            onChange={handleRadioButtonClick}
            checked ={currentInputSize === allowedSize}
          />
          <label htmlFor={InputSizeConfig[allowedSize].id}>{InputSizeConfig[allowedSize].label}</label>
        </div> 
  ))}
      </div>
    </div>
  );
};

export default InputSizeRadioGroup;
