import {nanoid} from 'nanoid';

import {FlowGraph} from '@/api/types';
import {G6ItemTypes} from '@/G6';
import {ErrorType, FlowError} from '@/store';
import {MessageType} from '@riga-claims/atlas-models';

const SECTION_ID_IS_NOT_REQUIRED_MESSAGE_TYPES = [
  MessageType.EndOfConversation
]

const validateSectionIdIsNotSet = (flow: FlowGraph): FlowError[] => {
  const flowErrors: FlowError[] = [];

  const flowSectionNodes = flow.nodes.filter(
    (node) => node.messageType === MessageType.Section
  );

  if (flowSectionNodes.length) {
    flow.nodes
      .filter((node) => SECTION_ID_IS_NOT_REQUIRED_MESSAGE_TYPES.includes(node.messageType))
      .filter(
        (node) =>
          !!node?.properties?.sectionId &&
          node?.properties?.sectionId?.value !== ''
      )
      .filter((node) =>
        flow.edges.some(
          (edge) => edge.source === node.id || edge.target === node.id
        )
      )
      .map((node) => {
        flowErrors.push({
          id: nanoid(),
          errorType: ErrorType.SECTION_ID_IS_NOT_REQUIRED,
          erroneousItemId: node.id,
          erroneousItemType: G6ItemTypes.NODE,
          message: `Section ID is not required for node "${
            node.name || node.id
          }"`,
        });
      });
  }

  return flowErrors;
};

export default validateSectionIdIsNotSet;
