// This is the entry point for the legacy summary pdf editor
// The component loads all legacy scripts, styles and returns the legacy HTML code from the old editor
// The legacy code should be viewed and accepted "as is"

// Functionality is ensured by providing "data" as an input to the PDF editor
// and receiving the modified "data" after the "Apply" button is clicked in the PDF editor

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { Graph } from '@antv/g6';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '@/appInsights/AppInsights';

import type { NodeParams } from '@riga-claims/atlas-models';
import { Loader, LoaderColor } from '@ids-react/loader';
import { ButtonText } from '@ids-react/button';

import { loadScript } from '@/utils';
import { TestIds } from '@/tests/testConstants';
import { nodeProperties } from '@/nodeVisualConfigurations';
import { useTrackException, useUpdateFlowGraphStore } from '@/hooks';

import './SummaryEditor.scss';

export interface SummaryEditorGlobals {
  Editor: any;
  SummaryEditor: any;
  summaryEditor: Record<string, any>;
  launchSummaryEditor: (data?: Record<string, unknown>) => void;
  updateSummaryProperty: (data: Record<string, unknown>) => void;
}

const BASE = '/legacySummaryEditorCode';
const legacyScripts = [
  `${BASE}/libs/btx-select.js`,
  `${BASE}/libs/bootstrap/dist/js/bootstrap.js`,
  `${BASE}/libs/summernote/dist/summernote.js`,
  `${BASE}/summary/SummaryElement.js`,
  `${BASE}/summary/dynamicItemList.js`,
  `${BASE}/summary/variableDialog.js`,
  `${BASE}/summary/messageDialog.js`,
  `${BASE}/summary/historyDialog.js`,
  `${BASE}/summary/tableDialog.js`,
  `${BASE}/summary/optionalTableDialog.js`,
  `${BASE}/summary/editor.js`,
  `${BASE}/summary/summaryEditor.js`,
  `${BASE}/summary/summaryUtils.js`,
];
const jQueyScript = `${BASE}/libs/jquery/dist/jquery.min.js`;
const selectricScript = `${BASE}/libs/jquery.selectric.js`;
export const startupScript = `${BASE}/startup.js`;

const loadLegacyScripts = async () => {
  try {
    await loadScript(jQueyScript);
    await loadScript(selectricScript);
    await Promise.all(legacyScripts.map((src) => loadScript(src)));
    await loadScript(startupScript);
  } catch (e) {
    const errorEvent = e as ErrorEvent;

    if (appInsights) {
      appInsights.trackException({
        severityLevel: SeverityLevel.Error,
        properties: {
          message: `Problem loading legacy summary editor scripts`,
          errorEvent,
        },
      });
    }
  }
};

export const LaunchSummaryEditorButton = ({
  value,
}: {
  value: string | undefined;
}): JSX.Element => {
  const trackException = useTrackException();

  const showButton = !!document.querySelectorAll(
    `script[src="${startupScript}"]`
  ).length;

  let parsedValue: undefined | Record<string, never>;
  try {
    if (value) {
      parsedValue = JSON.parse(value) as Record<string, never>;
    }
  } catch (e) {
    const error = e as SyntaxError;
    trackException(error, {
      message: error.message,
      name: error.name,
      stack: error.stack,
    });

    parsedValue = undefined;
  }

  return (
    <>
      <div
        id="launchSummaryEditorLoader"
        className={classNames({
          hidden: showButton,
        })}
      >
        <Loader color={LoaderColor.BLUE} />
      </div>
      <ButtonText
        id="launchSummaryEditorButton"
        className={classNames({
          hidden: !showButton,
        })}
        onClick={() => window.launchSummaryEditor(parsedValue)}
        style={{
          height: '1.9375rem',
          marginBottom: '0.75rem',
          paddingLeft: '1.3125rem',
          fontSize: '0.875rem',
        }}
      >
        {nodeProperties.summaryTemplateData.label}
      </ButtonText>
    </>
  );
};

interface SummaryEditorProps {
  graph: Graph;
  selectedNodeParams?: NodeParams;
}

const SummaryEditor: FunctionComponent<SummaryEditorProps> = ({
  graph,
  selectedNodeParams,
}: SummaryEditorProps): JSX.Element => {
  const { updateFlowGraphStore } = useUpdateFlowGraphStore(graph);

  useEffect(() => {
    if (!document.querySelectorAll(`script[src="${startupScript}"]`).length) {
      void loadLegacyScripts();
    }
  }, []);

  window.updateSummaryProperty = (data) => {
    if (selectedNodeParams) {
      const { properties: previousProperties, id } = selectedNodeParams;

      graph.updateItem(id, {
        properties: {
          ...previousProperties,
          summaryTemplateData: { value: JSON.stringify(data) },
        },
      });

      updateFlowGraphStore();
    }
  };

  return (
    <div id="summaryGeneratorOverlay" data-testid={TestIds.SUMMARY_EDITOR}>
      {/* Summary Editor */}
      <div
        style={{
          overflowY: 'auto',
          padding: '1rem 0',
          border: '1px solid black',
          backgroundColor: 'white',
          position: 'absolute',
          top: '5%',
          left: '5%',
          width: '90%',
          height: '90%',
        }}
      >
        <div className="container-fluid" style={{ height: '100%' }}>
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                className="close pull-right"
                onClick={() => window.summaryEditor.close()}
              >
                <span>&times;</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <h4>Editor</h4>
              <div id="summernote" />
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => window.summaryEditor.save()}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <h4>Dynamic items</h4>
              <div
                id="dynamicItemList"
                style={{ height: '100%', overflow: 'auto' }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Summary Editor Modals */}
      <div
        className="modal fade"
        id="insertVariableModal"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() =>
                  window.summaryEditor.closeDialog('variableDialog')
                }
              >
                <span>&times;</span>
              </button>
              <h4 className="modal-title">Variable</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal">
                <input type="hidden" id="variableId" />
                <div className="form-group">
                  <label htmlFor="variable" className="col-sm-2 control-label">
                    Variable
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="variable"
                        type="text"
                        className="btx-form__input"
                        required
                      />
                      <span className="btx-form__input-label">Variable</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="variableDisplayName"
                    className="col-sm-2 control-label"
                  >
                    Display Name
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="variableDisplayName"
                        type="text"
                        className="btx-form__input"
                        required
                      />
                      <span className="btx-form__input-label">
                        Display Name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="style" className="col-sm-2 control-label">
                    Style
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select id="style" className="btx-select selectric">
                          <option value="0">None</option>
                          <option value="1">H1</option>
                          <option value="2">H2</option>
                          <option value="3">H3</option>
                          <option value="4">Body</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="formatting"
                    className="col-sm-2 control-label"
                  >
                    Formatting
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="formatting"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">Bold</option>
                          <option value="2">Italic</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="textAlign" className="col-sm-2 control-label">
                    Text align
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select id="textAlign" className="btx-select selectric">
                          <option value="0">Left</option>
                          <option value="1">Right</option>
                          <option value="2">Center</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() =>
                  window.summaryEditor.closeDialog('variableDialog')
                }
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  window.summaryEditor.insertOrUpdateElement(
                    'summary-variable',
                    'variableDialog'
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="insertMessageModal"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() =>
                  window.summaryEditor.closeDialog('messageDialog')
                }
              >
                <span>&times;</span>
              </button>
              <h4 className="modal-title">Summary message</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal">
                <input type="hidden" id="messageId" />
                <div className="form-group">
                  <label htmlFor="message" className="col-sm-2 control-label">
                    Message
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="message"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Message</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="messageDisplayName"
                    className="col-sm-2 control-label"
                  >
                    Display Name
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="messageDisplayName"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">
                        Display Name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="messageStyle"
                    className="col-sm-2 control-label"
                  >
                    Style
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="messageStyle"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">H1</option>
                          <option value="2">H2</option>
                          <option value="3">H3</option>
                          <option value="4">Body</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="messageFormatting"
                    className="col-sm-2 control-label"
                  >
                    Formatting
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="messageFormatting"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">Bold</option>
                          <option value="2">Italic</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="messageTextAlign"
                    className="col-sm-2 control-label"
                  >
                    Text align
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="messageTextAlign"
                          className="btx-select selectric"
                        >
                          <option value="0">Left</option>
                          <option value="1">Right</option>
                          <option value="2">Center</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() =>
                  window.summaryEditor.closeDialog('messageDialog')
                }
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  window.summaryEditor.insertOrUpdateElement(
                    'summary-message',
                    'messageDialog'
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="insertHistoryModal"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() =>
                  window.summaryEditor.closeDialog('historyDialog')
                }
              >
                <span>&times;</span>
              </button>
              <h4 className="modal-title">Conversation history</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal">
                <input type="hidden" id="historyId" />
                <div className="form-group">
                  <label htmlFor="heading" className="col-sm-2 control-label">
                    Heading
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="heading"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Heading</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="historyDisplayName"
                    className="col-sm-2 control-label"
                  >
                    Display Name
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="historyDisplayName"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">
                        Display Name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="headingStyle"
                    className="col-sm-2 control-label"
                  >
                    Style
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="headingStyle"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">H1</option>
                          <option value="2">H2</option>
                          <option value="3">H3</option>
                          <option value="4">Body</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() =>
                  window.summaryEditor.closeDialog('historyDialog')
                }
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  window.summaryEditor.insertOrUpdateElement(
                    'summary-history',
                    'historyDialog'
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="insertTableModal"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => window.summaryEditor.closeDialog('tableDialog')}
              >
                <span>&times;</span>
              </button>
              <h4 className="modal-title">Dynamic table</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal">
                <input type="hidden" id="tableId" />
                <div className="form-group">
                  <label
                    htmlFor="tableHeading"
                    className="col-sm-2 control-label"
                  >
                    Heading
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="tableHeading"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Heading</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="tableDisplayName"
                    className="col-sm-2 control-label"
                  >
                    Display Name
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="tableDisplayName"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">
                        Display Name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="tableHeadingStyle"
                    className="col-sm-2 control-label"
                  >
                    Style
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="tableHeadingStyle"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">H1</option>
                          <option value="2">H2</option>
                          <option value="3">H3</option>
                          <option value="4">Body</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="dataSource"
                    className="col-sm-2 control-label"
                  >
                    Data source
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="dataSource"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Data source</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="tableVisibleRegex"
                    className="col-sm-2 control-label"
                  >
                    Visibility
                  </label>
                  <div className="col-sm-4">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="tableVisibleRegex"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Regex</span>
                    </div>
                  </div>
                  <label
                    htmlFor="tableVisibleCompareTo"
                    className="col-sm-2 control-label"
                  >
                    Compare to
                  </label>
                  <div className="col-sm-4">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="tableVisibleCompareTo"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Compare to</span>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => window.summaryEditor.tableDialog.addColumn()}
                  style={{ marginBottom: '1rem' }}
                >
                  Add column
                </button>
              </form>
              <div className="form-group" id="tableColumnContainer" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => window.summaryEditor.closeDialog('tableDialog')}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  window.summaryEditor.insertOrUpdateElement(
                    'summary-table',
                    'tableDialog'
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="insertOptionalTableModal"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() =>
                  window.summaryEditor.closeDialog('optionalTableDialog')
                }
              >
                <span>&times;</span>
              </button>
              <h4 className="modal-title">Optional table</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal">
                <input type="hidden" id="optionalTableId" />
                <div className="form-group">
                  <label
                    htmlFor="optionalTableHeading"
                    className="col-sm-2 control-label"
                  >
                    Heading
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="optionalTableHeading"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Heading</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="optionalTableDisplayName"
                    className="col-sm-2 control-label"
                  >
                    Display Name
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="optionalTableDisplayName"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">
                        Display Name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="optionalTableHeadingStyle"
                    className="col-sm-2 control-label"
                  >
                    Style
                  </label>
                  <div className="col-sm-10">
                    <div className="btx-form__select-wrapper btx-form__input-wrapper">
                      <div className="selectric-wrapper selectric-btx-select">
                        <select
                          id="optionalTableHeadingStyle"
                          className="btx-select selectric"
                        >
                          <option value="0">None</option>
                          <option value="1">H1</option>
                          <option value="2">H2</option>
                          <option value="3">H3</option>
                          <option value="4">Body</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="optionalTableVisibleRegex"
                    className="col-sm-2 control-label"
                  >
                    Visibility
                  </label>
                  <div className="col-sm-4">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="optionalTableVisibleRegex"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Regex</span>
                    </div>
                  </div>
                  <label
                    htmlFor="optionalTableVisibleCompareTo"
                    className="col-sm-2 control-label"
                  >
                    Compare to
                  </label>
                  <div className="col-sm-4">
                    <div className="btx-form__input-wrapper">
                      <input
                        id="optionalTableVisibleCompareTo"
                        type="text"
                        className="btx-form__input"
                      />
                      <span className="btx-form__input-label">Compare to</span>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() =>
                    window.summaryEditor.optionalTableDialog.addRow()
                  }
                  style={{ marginBottom: '1rem' }}
                >
                  Add row
                </button>
              </form>
              <div className="form-group" id="tableRowContainer" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() =>
                  window.summaryEditor.closeDialog('optionalTableDialog')
                }
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  window.summaryEditor.insertOrUpdateElement(
                    'summary-optional-table',
                    'optionalTableDialog'
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryEditor;
