import {
  NodeProperty,
  BaseNodeProperty,
  InputSsnNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class InputSsnLayout extends BaseLayout {
  constructor(node: InputSsnNode) {
    super(node);

    const { properties } = node;

    const { variableName, message } = BaseNodeProperty;

    const { placeholder, countryCode } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: placeholder,
          label: nodeProperties[placeholder].label,
          placeholder: nodeProperties[placeholder].placeholder,
          ...properties[placeholder],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: countryCode,
          label: nodeProperties[countryCode].label,
          placeholder: nodeProperties[countryCode].placeholder,
          ...properties[countryCode],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputSsnLayout;
