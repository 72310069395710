import { Arrow } from '@antv/g6';

import { Colors } from '@/constants';
import { G6EdgeStates, G6Styles, ItemSize } from '../../G6Types';

export type EdgeStatesWithStyle =
  | G6EdgeStates.DEFAULT
  | G6EdgeStates.SELECTED
  | G6EdgeStates.HOVER
  | G6EdgeStates.MARKED
  | G6EdgeStates.MARKED_HIGHLIGHTED;

export const getSizedEdgeElements = (
  itemSize?: ItemSize
): {
  lineWidth: number;
  startArrowPath: string;
  endArrowPath: string;
} => {
  switch (itemSize) {
    case ItemSize.SM:
      return {
        lineWidth: 5,
        startArrowPath: '',
        endArrowPath: Arrow.triangle(60, 52),
      };

    case ItemSize.MD:
      return {
        lineWidth: 3.5,
        startArrowPath: Arrow.circle(20, -7),
        endArrowPath: Arrow.triangle(45, 39),
      };

    case ItemSize.LG:
    default:
      return {
        lineWidth: 2,
        startArrowPath: Arrow.circle(8, -4),
        endArrowPath: Arrow.triangle(21, 18),
      };
  }
};

export interface EdgeStateStyle {
  [G6Styles.STROKE]: string;
  [G6Styles.LINE_WIDTH]: number;
  [G6Styles.LINE_APPEND_WIDTH]?: number;
  [G6Styles.START_ARROW]: {
    [G6Styles.PATH]: string;
    [G6Styles.FILL]: string;
  };
  [G6Styles.END_ARROW]: {
    [G6Styles.PATH]: string;
    [G6Styles.FILL]: string;
  };
}

export const getEdgeStateStyles = (
  itemSize?: ItemSize
): Record<EdgeStatesWithStyle, EdgeStateStyle> => {
  const { lineWidth, startArrowPath, endArrowPath } =
    getSizedEdgeElements(itemSize);

  return {
    [G6EdgeStates.DEFAULT]: {
      [G6Styles.STROKE]: Colors.BROWN_200,
      [G6Styles.LINE_WIDTH]: lineWidth,
      [G6Styles.LINE_APPEND_WIDTH]: 15,
      [G6Styles.START_ARROW]: {
        [G6Styles.PATH]: startArrowPath,
        [G6Styles.FILL]: Colors.BEIGE_100,
      },
      [G6Styles.END_ARROW]: {
        [G6Styles.PATH]: endArrowPath,
        [G6Styles.FILL]: Colors.BROWN_200,
      },
    },
    [G6EdgeStates.SELECTED]: {
      [G6Styles.STROKE]: Colors.BLUE_100,
      [G6Styles.LINE_WIDTH]: lineWidth * 2,
      [G6Styles.START_ARROW]: {
        [G6Styles.PATH]: startArrowPath,
        [G6Styles.FILL]: Colors.BEIGE_100,
      },
      [G6Styles.END_ARROW]: {
        [G6Styles.PATH]: endArrowPath,
        [G6Styles.FILL]: Colors.BLUE_100,
      },
    },
    [G6EdgeStates.HOVER]: {
      [G6Styles.STROKE]: Colors.BLUE_100,
      [G6Styles.LINE_WIDTH]: lineWidth,
      [G6Styles.START_ARROW]: {
        [G6Styles.PATH]: startArrowPath,
        [G6Styles.FILL]: Colors.BEIGE_100,
      },
      [G6Styles.END_ARROW]: {
        [G6Styles.PATH]: endArrowPath,
        [G6Styles.FILL]: Colors.BLUE_100,
      },
    },
    [G6EdgeStates.MARKED]: {
      [G6Styles.STROKE]: Colors.BROWN_200,
      [G6Styles.LINE_WIDTH]: lineWidth * 4,
      [G6Styles.START_ARROW]: {
        [G6Styles.PATH]: startArrowPath,
        [G6Styles.FILL]: Colors.BROWN_200,
      },
      [G6Styles.END_ARROW]: {
        [G6Styles.PATH]: endArrowPath,
        [G6Styles.FILL]: Colors.BROWN_200,
      },
    },
    [G6EdgeStates.MARKED_HIGHLIGHTED]: {
      [G6Styles.STROKE]: Colors.ORANGE_100,
      [G6Styles.LINE_WIDTH]: lineWidth * 6,
      [G6Styles.START_ARROW]: {
        [G6Styles.PATH]: startArrowPath,
        [G6Styles.FILL]: Colors.ORANGE_100,
      },
      [G6Styles.END_ARROW]: {
        [G6Styles.PATH]: endArrowPath,
        [G6Styles.FILL]: Colors.ORANGE_100,
      },
    },
  };
};

export interface LabelStateStyles {
  [G6Styles.STROKE]: string;
  [G6Styles.LINE_WIDTH]: number;
}

export const labelStateStyles: Record<EdgeStatesWithStyle, LabelStateStyles> = {
  [G6EdgeStates.DEFAULT]: {
    [G6Styles.STROKE]: Colors.BEIGE_100,
    [G6Styles.LINE_WIDTH]: 1,
  },
  [G6EdgeStates.SELECTED]: {
    [G6Styles.STROKE]: Colors.BLUE_100,
    [G6Styles.LINE_WIDTH]: 2,
  },
  [G6EdgeStates.HOVER]: {
    [G6Styles.STROKE]: Colors.BLUE_100,
    [G6Styles.LINE_WIDTH]: 1,
  },
  [G6EdgeStates.MARKED]: {
    [G6Styles.STROKE]: Colors.BROWN_200,
    [G6Styles.LINE_WIDTH]: 4,
  },
  [G6EdgeStates.MARKED_HIGHLIGHTED]: {
    [G6Styles.STROKE]: Colors.ORANGE_100,
    [G6Styles.LINE_WIDTH]: 6,
  },
};
