import {
  BaseNode,
  BaseNodeProperty,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  ReadOnlyFieldParams,
  TextFieldParams,
  TextAreaFieldParams,
  CheckboxFieldParams,
  DropdownFieldParams,
  CustomPropertyParams,
  JSXElementFieldParams,
  ReadOnlyFieldData,
  TextFieldData,
  TextAreaFieldData,
  CheckboxFieldData,
  DropdownFieldData,
  CustomPropertyFieldData,
  JSXElementFieldData,
  FieldData,
} from './FieldData';
import { nanoid } from 'nanoid';
import { baseNodeProperties } from './labels';

class GroupData {
  private fields: Array<FieldData | JSXElementFieldData> = [];
  public key = nanoid();
  public label?: string;
  public collapsedByDefault?: boolean;

  constructor(label?: string, collapsedByDefault?: boolean) {
    this.label = label;
    this.collapsedByDefault = collapsedByDefault;
  }

  public addReadOnlyField = (
    params: ReadOnlyFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new ReadOnlyFieldData(params));
    mappedFields?.push(params.fieldName);
    return this;
  };

  public addTextField = (
    params: TextFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new TextFieldData(params));
    mappedFields?.push(params.fieldName);
    return this;
  };

  public addTextAreaField = (
    params: TextAreaFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new TextAreaFieldData(params));
    mappedFields?.push(params.fieldName);
    return this;
  };

  public addCheckboxField = (
    params: CheckboxFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new CheckboxFieldData(params));
    mappedFields?.push(params.fieldName);
    return this;
  };

  public addDropdownField = (
    params: DropdownFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new DropdownFieldData(params));
    mappedFields?.push(params.fieldName);
    return this;
  };

  // Adds dropdown filed on the top of other fields
  public addDropdownFieldAtTop = (
    params: DropdownFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.splice(0, 0, new DropdownFieldData(params));
    mappedFields?.splice(0, 0, params.fieldName);
    return this;
  };

  public addCustomPropertyField = (params: CustomPropertyParams): GroupData => {
    this.fields.push(new CustomPropertyFieldData(params));
    return this;
  };

  public addDefaultValueField = (
    node: BaseNode,
    mappedFields: Array<NodeProperty | BaseNodeProperty> = []
  ): void => {
    const { defaultValue } = BaseNodeProperty;
    const { properties } = node;

    this.addTextField(
      {
        fieldName: defaultValue,
        label: baseNodeProperties[defaultValue].label,
        placeholder: baseNodeProperties[defaultValue].placeholder,
        ...properties[defaultValue],
      },
      mappedFields
    );
  };

  public addJSXElementField = (
    params: JSXElementFieldParams,
    mappedFields?: Array<NodeProperty | string>
  ): GroupData => {
    this.fields.push(new JSXElementFieldData(params));
    if (mappedFields && params.fieldName) {
      mappedFields.push(params.fieldName);
    }
    return this;
  };

  public getFields = (): Array<FieldData | JSXElementFieldData> => this.fields;
}

export default GroupData;
