import {
  BaseNode,
  BaseNodeProperty,
  NodeDefinitionProperties,
  NodeDefinitionProperty,
  NodeOutputProperties,
  NodeParamProperties,
  NodeParamProperty,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '@/nodeVisualConfigurations/nodeProperties/labels';
import TabData from '@/nodeVisualConfigurations/nodeProperties/TabData';

import AccessLogDataGroup from '@/components/AccessLogDataGroup/AccessLogDataGroup';
import GroupData from '../nodeProperties/GroupData';

class BaseLayout {
  public node: BaseNode;
  public contentTab: TabData = new TabData();
  public validationTab: TabData = new TabData();
  public propertiesTab: TabData = new TabData();
  public tabs: TabData[] = [
    this.contentTab,
    this.validationTab,
    this.propertiesTab,
  ];
  public mappedFields: Array<NodeProperty | BaseNodeProperty> = [];

  constructor(node: BaseNode) {
    this.node = node;
  }

  protected mapCustomProps = (): void => {
    const customPropertiesGroup =
      this.propertiesTab.getOrAddCustomPropertyGroup();

    Object.entries(this.node.customProperties).forEach(([key, value]) => {
      customPropertiesGroup.addCustomPropertyField({
        label: key,
        ...value,
      });
    });
  };

  protected mapRemainingProps = (): void => {
    const remainingFieldGroup =
      this.propertiesTab.getOrAddRemainingFieldGroup();

    const addMissedProperties = (
      properties: NodeDefinitionProperties | NodeParamProperties
    ) =>
      Object.entries(properties)
        .filter(
          ([propertyKey, propertyValue]) =>
            !this.mappedFields?.includes(
              propertyKey as NodeProperty | BaseNodeProperty
            ) && propertyValue
        )
        .forEach(([_propertyKey, _propertyValue]) => {
          const propertyKey = _propertyKey as NodeProperty | BaseNodeProperty;
          const propertyValue = _propertyValue as
            | NodeDefinitionProperty
            | NodeParamProperty;
          remainingFieldGroup.addTextField(
            {
              fieldName: propertyKey,
              label: propertyKey,
              value: propertyValue.value || propertyValue.values?.join() || '',
            },
            this.mappedFields
          );
        });

    const addOutputProperties = (nodeOutputs: NodeOutputProperties[]): void => {
      if (!nodeOutputs || nodeOutputs.length === 0) {
        return;
      }
      const group = this.propertiesTab.addGroup('Outputs', false);
      nodeOutputs.forEach((nodeOutput) => {
        this.addOutputField(
          group,
          nodeOutput.name,
          nodeOutput.possibleValues?.join(', ')
        );
      });
    };

    this.addSectionIdField();

    this.addIsRequiredValidationFields();

    this.addDefaultValidationMessageField();
    // Maps remaining properties from the node definition
    addMissedProperties(this.node.properties);

    // Maps remaining properties from the node data
    addMissedProperties(this.node.nodeParams.properties);

    addOutputProperties(this.node.outputProperties);
  };

  protected addSummaryOverviewGroup = (): void => {
    const { summaryMessage, excludeFromSummary } = BaseNodeProperty;
    const { properties } = this.node;

    this.propertiesTab
      .addGroup(nodePropertiesGroups.summaryOverviewGroupTitle, true)
      .addTextAreaField(
        {
          fieldName: summaryMessage,
          label: baseNodeProperties[summaryMessage].label,
          placeholder: baseNodeProperties[summaryMessage].placeholder,
          ...properties[summaryMessage],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: excludeFromSummary,
          label: baseNodeProperties[excludeFromSummary].label,
          ...properties[excludeFromSummary],
        },
        this.mappedFields
      );
  };

  protected addAccessLogDataGroup = (params?: {
    hasEnteredData?: boolean;
  }): void => {
    const { answerCanContainPersonalData, questionContainsPersonalData } =
      BaseNodeProperty;
    const { properties } = this.node;

    const dataLoggingGroup = this.contentTab.addGroup(
      nodePropertiesGroups.dataLogging,
      true
    );
    dataLoggingGroup.addCheckboxField(
      {
        fieldName: questionContainsPersonalData,
        label: baseNodeProperties[questionContainsPersonalData].label,
        title: baseNodeProperties[questionContainsPersonalData].title,
        ...properties[questionContainsPersonalData],
      },
      this.mappedFields
    );

    if (params?.hasEnteredData) {
      dataLoggingGroup.addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );
    }

    dataLoggingGroup.addJSXElementField({
      renderElement: (props) => (
        <AccessLogDataGroup
          {...props}
          hasEnteredData={params?.hasEnteredData}
        />
      ),
    });
  };

  protected addSectionIdField = (): void => {
    const { sectionId } = BaseNodeProperty;
    const { properties } = this.node;

    this.contentTab.addGroup().addTextField(
      {
        fieldName: sectionId,
        label: baseNodeProperties[sectionId].label,
        placeholder: baseNodeProperties[sectionId].placeholder,
        ...properties[sectionId],
      },
      this.mappedFields
    );
  };
  protected addDefaultValidationMessageField = (): void => {
    const { defaultValidationMessage } = BaseNodeProperty;
    const { properties } = this.node;

    this.propertiesTab.addGroup().addReadOnlyField(
      {
        fieldName: defaultValidationMessage,
        label: baseNodeProperties[defaultValidationMessage].label,
        ...properties[defaultValidationMessage],
      },
      this.mappedFields
    );
  };

  protected addIsRequiredValidationFields = (): void => {
    const { isRequired, isRequiredValidationMessage } = BaseNodeProperty;
    const { properties } = this.node;

    this.validationTab
      .addGroup()
      .addCheckboxField(
        {
          fieldName: isRequired,
          label: baseNodeProperties[isRequired].label,
          title: baseNodeProperties[isRequired].title,
          ...properties[isRequired],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: isRequiredValidationMessage,
          label: baseNodeProperties[isRequiredValidationMessage].label,
          title: baseNodeProperties[isRequiredValidationMessage].title,
          ...properties[isRequiredValidationMessage],
        },
        this.mappedFields
      );
  };

  protected addCommentField = (): void => {
    const { comment } = BaseNodeProperty;
    const { properties } = this.node;

    this.contentTab.addGroup().addTextAreaField(
      {
        fieldName: comment,
        label: baseNodeProperties[comment].label,
        placeholder: baseNodeProperties[comment].placeholder,
        ...properties[comment],
      },
      this.mappedFields
    );
  };

  protected addOutputField = (
    group: GroupData,
    label: string,
    value: string
  ): void => {
    const { comment } = BaseNodeProperty;

    group.addReadOnlyField({
      fieldName: comment,
      label: label,
      value: value,
    });
  };
}

export default BaseLayout;
