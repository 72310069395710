import { Country } from '@riga-claims/atlas-models';

const countries = {
  [Country.Common]: '',
  [Country.Denmark]: 'Denmark',
  [Country.Finland]: 'Finland',
  [Country.Nordic]: 'Nordic',
  [Country.Norway]: 'Norway',
  [Country.Sweden]: 'Sweden',
  [Country.Baltics]: 'Baltics',
  [Country.Test]: 'Test',
  [Country.UserTest]: 'UserTest',
};

export default countries;
