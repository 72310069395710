import {
  BaseNodeProperty,
  NodeProperty,
  SaveDraftNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SaveDraftLayout extends BaseLayout {
  constructor(node: SaveDraftNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { reporterId } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: reporterId,
          label: nodeProperties[reporterId].label,
          placeholder: nodeProperties[reporterId].placeholder,
          ...properties[reporterId],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SaveDraftLayout;
