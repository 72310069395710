import { BaseNodeProperty, MessageNode } from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class InputTextLayout extends BaseLayout {
  constructor(node: MessageNode) {
    super(node);

    const { properties } = node;
    const { message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputTextLayout;
