import {
  ChangeEventHandler,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { AccessLogData, AccessType } from '@riga-claims/atlas-models';
import { InputField, InputType } from '@riga-claims/storybook';

import { JSXElementRenderProps } from '@/nodeVisualConfigurations';

import AccessedDataList from './AccessedDataList/AccessedDataList';

import classes from './AccessLogDataGroup.module.scss';

export const LABELS = {
  dataSubject: 'Data subject',
  dataCustomerSaw: 'Data customer saw',
  dataCustomerEntered: 'Data customer entered',
  helpTextLabel:
    'Owner of the data that the person filing a claim will see or enter',
};

export type AccessLogDataGroupProps = JSXElementRenderProps & {
  hasEnteredData?: boolean;
};

const AccessLogDataGroup: FunctionComponent<AccessLogDataGroupProps> = ({
  graph,
  selectedNodeParams,
  debouncedUpdateFlowGraphStore,
  hasEnteredData,
}: AccessLogDataGroupProps) => {
  const paramAccessLogData = selectedNodeParams.accessLogData?.[0]?.dataSubject;

  const [dataSubject, setDataSubject] = useState(paramAccessLogData || '');

  useEffect(() => {
    if (paramAccessLogData) {
      setDataSubject(paramAccessLogData);
    }
  }, [paramAccessLogData]);

  const accessLogData = selectedNodeParams.accessLogData || [];

  const handleDataSubjectChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (accessLogData.length) {
      const newValue = event.target.value;

      setDataSubject(newValue);

      const newAccessLogData: AccessLogData = accessLogData.map(
        (accessLogDataItem) => ({
          ...accessLogDataItem,
          dataSubject: newValue,
        })
      );

      graph.updateItem(selectedNodeParams.id, {
        accessLogData: newAccessLogData,
      });

      debouncedUpdateFlowGraphStore();
    }
  };

  return (
    <div className={classes.AccessLogDataGroup}>
      <AccessedDataList
        graph={graph}
        selectedNodeParams={selectedNodeParams}
        debouncedUpdateFlowGraphStore={debouncedUpdateFlowGraphStore}
        accessLogData={accessLogData}
        dataSubject={dataSubject}
        accessType={AccessType.Seen}
        title={LABELS.dataCustomerSaw}
      />
      {hasEnteredData && (
        <AccessedDataList
          graph={graph}
          selectedNodeParams={selectedNodeParams}
          debouncedUpdateFlowGraphStore={debouncedUpdateFlowGraphStore}
          accessLogData={accessLogData}
          dataSubject={dataSubject}
          accessType={AccessType.Entered}
          title={LABELS.dataCustomerEntered}
        />
      )}
      <div className={classes.DataSubject}>
        <InputField
          id="data-subject"
          name="data-subject"
          type={InputType.TEXT}
          value={dataSubject}
          onChange={handleDataSubjectChange}
          helpLabel={LABELS.helpTextLabel}
          disableMaxWidth
          compact
          disabled={!accessLogData.length}
          label={LABELS.dataSubject}
          autocomplete="off"
        />
      </div>
    </div>
  );
};

export default memo(AccessLogDataGroup);
