/** Returns a newline \r or \rn character */
export const NEWLINE = /\r?\n|\r/g;

/** Returns a string without spaces */
export const NO_SPACES = /^\S*$/;

/** Returns \ ^ $ * + ? . ( ) | { } [ ] symbols */
export const RESERVED_REGEX_CHARACTERS = /[.*+?^${}()|[\]\\]/g;

/** Returns characters, digits and - . # symbols between braces {} */
export const TEXT_BETWEEN_BRACES = /{[A-z0-9-.#]+}/g;
