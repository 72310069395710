import { BaseNodeProperty, SetValueNode } from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

const labels = {
  value: 'Value to set',
  valuePlaceholder: 'Add value to set',
};

class SetValueLayout extends BaseLayout {
  constructor(node: SetValueNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: message,
          label: labels.value,
          placeholder: labels.valuePlaceholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SetValueLayout;
