import { G6ItemTypes } from '@/G6';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ErrorType {
  START_NODE_DOES_NOT_EXIST = 'START_NODE_DOES_NOT_EXIST',
  MULTIPLE_START_NODES_EXIST = 'MULTIPLE_START_NODES_EXIST',
  NODE_TYPE_IS_NOT_SET = 'NODE_TYPE_IS_NOT_SET',
  CUSTOM_PROPERTY_NAME_IS_NOT_SET = 'CUSTOM_PROPERTY_NAME_IS_NOT_SET',
  EDGE_ERROR = 'EDGE_ERROR',
  UNSUPPORTED_NODE_NAME_REFERENCE = 'UNSUPPORTED_NODE_NAME_REFERENCE',
  SPLITTER_CONNECTION_MISSING_REFERENCE = 'SPLITTER_CONNECTION_MISSING_REFERENCE',
  SPLITTER_CONNECTION_MISSING_ORDER = 'SPLITTER_CONNECTION_MISSING_ORDER',
  SPLITTER_CONNECTION_DUPLICATE_ORDER = 'SPLITTER_CONNECTION_DUPLICATE_ORDER',
  SPLITTER_CONNECTION_DUPLICATE_CONDITIONS = 'SPLITTER_CONNECTION_DUPLICATE_CONDITIONS',
  SECTION_ID_IS_NOT_SET = 'SECTION_ID_IS_NOT_SET',
  SECTION_ID_IS_NOT_REQUIRED = 'SECTION_ID_IS_NOT_REQUIRED',
  MULTIPLE_CHOICE_SPLIT_CHAR_MISSING = 'MULTIPLE_CHOICE_SPLIT_CHAR_MISSING',
  MULTIPLE_CHOICE_SPLIT_CHAR_USED = 'MULTIPLE_CHOICE_SPLIT_CHAR_USED',
}

export type ErroneousItemType = G6ItemTypes | null;

export interface FlowError {
  id: string;
  errorType: ErrorType;
  erroneousItemType: ErroneousItemType;
  erroneousItemId: string | null;
  message: string;
}

export interface ErrorState {
  errors: FlowError[];
}

export const initialErrorState: ErrorState = {
  errors: [],
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: initialErrorState,
  reducers: {
    setErrors: (state, action: PayloadAction<FlowError[]>) => {
      state.errors = action.payload;
    },
    resetErrorState: () => initialErrorState,
  },
});

export const { setErrors, resetErrorState } = errorSlice.actions;

export default errorSlice.reducer;
