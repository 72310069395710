import {
  NodeProperty,
  BaseNodeProperty,
  Norway_GetUserInformationNode,
  Denmark_GetUserInformationNode,
  Finland_Travel_GetUserInformationNode,
  Finland_WorkersCompensation_GetUserInformationNode,
  MessageType,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class GetUserInformationLayout extends BaseLayout {
  constructor(
    node:
      | Norway_GetUserInformationNode
      | Denmark_GetUserInformationNode
      | Finland_Travel_GetUserInformationNode
      | Finland_WorkersCompensation_GetUserInformationNode
  ) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const {
      ssn,
      reporterSsn,
      nationalIdentity,
      nationalIdentityCountry,
      customerNumber,
    } = NodeProperty;

    switch (node.messageType) {
      case MessageType.Norway_GetUserInformation:
        this.contentTab
          .addGroup(nodePropertiesGroups.inputValidation)
          .addTextField(
            {
              fieldName: ssn,
              label: 'SSN',
              placeholder: 'SSN here',
              ...(node as Norway_GetUserInformationNode).properties[ssn],
            },
            this.mappedFields
          );
        break;

      case MessageType.Denmark_GetUserInformation:
        this.contentTab
          .addGroup(nodePropertiesGroups.inputValidation)
          .addTextField(
            {
              fieldName: ssn,
              label: 'SSN',
              placeholder: 'SSN here',
              ...(node as Denmark_GetUserInformationNode).properties[ssn],
            },
            this.mappedFields
          );
        break;

      case MessageType.Finland_Travel_GetUserInformation:
        this.contentTab
          .addGroup(nodePropertiesGroups.inputValidation)
          .addTextField(
            {
              fieldName: reporterSsn,
              label: 'Reporter SSN',
              placeholder: 'Reporter SSN here',
              ...(node as Finland_Travel_GetUserInformationNode).properties[
                reporterSsn
              ],
            },
            this.mappedFields
          );
        break;

      case MessageType.Finland_WorkersCompensation_GetUserInformation:
        this.contentTab
          .addGroup(nodePropertiesGroups.inputValidation)
          .addTextField(
            {
              fieldName: nationalIdentity,
              label: 'National identity',
              placeholder: 'National identity',
              ...(node as Finland_WorkersCompensation_GetUserInformationNode)
                .properties[nationalIdentity],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: nationalIdentityCountry,
              label: 'National identity country',
              placeholder: 'National identity country',
              ...(node as Finland_WorkersCompensation_GetUserInformationNode)
                .properties[nationalIdentityCountry],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: customerNumber,
              label: 'Customer number',
              placeholder: 'Customer number',
              ...(node as Finland_WorkersCompensation_GetUserInformationNode)
                .properties[customerNumber],
            },
            this.mappedFields
          );
        break;

      default:
        break;
    }

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetUserInformationLayout;
