import { G6NodeStates, G6Styles } from '@/G6';
import { Colors } from '@/constants';

export type NodeStatesWithStyle =
  | G6NodeStates.DEFAULT
  | G6NodeStates.SELECTED
  | G6NodeStates.MULTIPLE_SELECTED
  | G6NodeStates.HOVER
  | G6NodeStates.HOVER_WITH_EDGE
  | G6NodeStates.MARKED
  | G6NodeStates.MARKED_HIGHLIGHTED
  | G6NodeStates.ERROR;

export interface NodeStateStyle {
  [G6Styles.STROKE]: string;
  [G6Styles.LINE_WIDTH]: number;
  [G6Styles.FILL]: string;
}

export const nodeStateStyles: Record<NodeStatesWithStyle, NodeStateStyle> = {
  [G6NodeStates.DEFAULT]: {
    [G6Styles.STROKE]: 'transparent',
    [G6Styles.LINE_WIDTH]: 0,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.SELECTED]: {
    [G6Styles.STROKE]: Colors.BLUE_100,
    [G6Styles.LINE_WIDTH]: 4,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.MULTIPLE_SELECTED]: {
    [G6Styles.STROKE]: Colors.BLUE_100,
    [G6Styles.LINE_WIDTH]: 4,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.HOVER]: {
    [G6Styles.STROKE]: Colors.BLUE_100,
    [G6Styles.LINE_WIDTH]: 2,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.HOVER_WITH_EDGE]: {
    [G6Styles.STROKE]: '#909DFF',
    [G6Styles.LINE_WIDTH]: 6,
    [G6Styles.FILL]: '#7CAAFF33',
  },
  [G6NodeStates.MARKED]: {
    [G6Styles.STROKE]: Colors.BROWN_200,
    [G6Styles.LINE_WIDTH]: 8,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.MARKED_HIGHLIGHTED]: {
    [G6Styles.STROKE]: Colors.ORANGE_100,
    [G6Styles.LINE_WIDTH]: 12,
    [G6Styles.FILL]: 'transparent',
  },
  [G6NodeStates.ERROR]: {
    [G6Styles.STROKE]: Colors.RED_100,
    [G6Styles.LINE_WIDTH]: 8,
    [G6Styles.FILL]: `${Colors.RED_100}33`,
  },
};
