import { Hostname, Environments } from '@/constants';

const getEnvironment = (): Environments => {
  const currentHostName = window.location.hostname;
  if (currentHostName.includes(Hostname.LOCALHOST)) {
    return Environments.DEV;
  }
  if (currentHostName.includes(Environments.ITEST)) {
    return Environments.ITEST;
  }
  if (currentHostName.includes(Environments.STEST)) {
    return Environments.STEST;
  }
  if (currentHostName.includes(Environments.ATEST)) {
    return Environments.ATEST;
  }
  return Environments.PROD;
};

export const getNextEnvironment = (
  currentEnvironment: Environments = getEnvironment()
): Environments | null => {
  switch (currentEnvironment) {
    case Environments.PROD:
      return null;
    case Environments.ATEST:
      return Environments.PROD;
    case Environments.STEST:
      return Environments.ATEST;
    case Environments.ITEST:
    case Environments.DEV:
      return Environments.STEST;
    default:
      return Environments.ITEST;
  }
};

export const getPreviousEnvironment = (
  currentEnvironment: Environments = getEnvironment()
): Environments | null => {
  switch (currentEnvironment) {
    case Environments.PROD:
      return Environments.ATEST;
    case Environments.ATEST:
      return Environments.STEST;
    case Environments.STEST:
      return Environments.ITEST;
    case Environments.ITEST:
    case Environments.DEV:
      return null;
    default:
      return null;
  }
};

export default getEnvironment;
