import {
  NodeProperty,
  Finland_WorkersCompensation_ShowDepartmentsNode,
} from '@riga-claims/atlas-models';

import { nodePropertiesGroups } from '../nodeProperties/labels';

import ClassifierLayout from './ClassifierLayout';

class FinlandWorkersCompShowDepartmentsLayout extends ClassifierLayout {
  constructor(node: Finland_WorkersCompensation_ShowDepartmentsNode) {
    super(node);

    const { policy } = NodeProperty;
    const customerFacingGroup = this.contentTab
      .getGroups()
      .find((x) => x.label === nodePropertiesGroups.customerFacing);

    if (customerFacingGroup) {
      customerFacingGroup.addTextField(
        {
          fieldName: policy,
          label: 'Policy',
          placeholder: 'Policy object from policy search',
          ...node.properties[policy],
        },
        this.mappedFields
      );
    }
  }
}

export default FinlandWorkersCompShowDepartmentsLayout;
