import { EditorConfiguration, EditorConfigurationKeys } from './types';

const itestConfig: EditorConfiguration = {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]:
    'dd9fe47b-590e-4aa3-b187-857fd1b974c3',
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]:
    '5c666962eb049c2df965b938',
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]:
    'B810121C-AC7F-4A5A-A6EE-BBFDFF2D8620',
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: 'Atlas Editor UI',
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: 'ITEST',
};

export default itestConfig;
