import { nanoid } from 'nanoid';
import {
  BaseNodeProperty,
  NodeParams,
  NodeProperty,
} from '@riga-claims/atlas-models';

import { G6ItemTypes } from '@/G6';
import { EdgeParams, FlowGraph } from '@/api/types';
import { ErrorType, FlowError } from '@/store';
import { TEXT_BETWEEN_BRACES } from '@/constants';

export const NODE_PROPERTIES_TO_IGNORE: string[] = [
  BaseNodeProperty.validationRegex,
  BaseNodeProperty.comment,
  NodeProperty.summaryTemplateData,
];

interface CreateNodeNameErrorParams {
  propertyValue: string;
  propertyKey: string;
  erroneousItemId: string;
  erroneousItemType: G6ItemTypes;
}

export const createNodeNameReferenceError = ({
  propertyValue,
  propertyKey,
  erroneousItemId,
  erroneousItemType,
}: CreateNodeNameErrorParams): FlowError[] =>
  propertyValue.match(TEXT_BETWEEN_BRACES)?.map((reference) => ({
    id: nanoid(),
    errorType: ErrorType.UNSUPPORTED_NODE_NAME_REFERENCE,
    erroneousItemId,
    erroneousItemType,
    message: `Unsupported ${reference} reference used in property "${propertyKey}" for ${erroneousItemType} "${erroneousItemId}"`,
  })) || [];

export const getNodeNameReferenceErrorsForNode = (node: NodeParams) =>
  [
    ...Object.entries(node.properties),
    ...Object.entries(node.customProperties),
  ].reduce<FlowError[]>((previousValue, [propertyKey, propertyValue]) => {
    let propertyErrors: FlowError[] = [];
    const { value } = propertyValue;

    if (
      typeof value === 'string' &&
      !NODE_PROPERTIES_TO_IGNORE.includes(propertyKey)
    ) {
      propertyErrors = createNodeNameReferenceError({
        propertyValue: value,
        propertyKey,
        erroneousItemId: node.id,
        erroneousItemType: G6ItemTypes.NODE,
      });
    }

    return previousValue.concat(propertyErrors);
  }, []);

export const getNodeNameReferenceErrorsForEdge = (edge: EdgeParams) =>
  Object.entries(edge).reduce<FlowError[]>(
    (previousValue, [propertyKey, propertyValue]) => {
      let propertyErrors: FlowError[] = [];

      if (typeof propertyValue === 'string' && propertyKey !== 'value') {
        propertyErrors = createNodeNameReferenceError({
          propertyValue,
          propertyKey,
          erroneousItemId: edge.id,
          erroneousItemType: G6ItemTypes.EDGE,
        });
      }

      return previousValue.concat(propertyErrors);
    },
    []
  );

export const getNodeNameReferenceErrorsForNodes = (nodes: NodeParams[]) =>
  nodes.reduce<FlowError[]>(
    (previousValue, node) =>
      previousValue.concat(getNodeNameReferenceErrorsForNode(node)),
    []
  );

export const getNodeNameReferenceErrorsForEdges = (edges: EdgeParams[]) =>
  edges.reduce<FlowError[]>(
    (previousValue, edge) =>
      previousValue.concat(getNodeNameReferenceErrorsForEdge(edge)),
    []
  );

const validateNodeNameIsNotUsedAsReference = (flow: FlowGraph): FlowError[] => [
  ...getNodeNameReferenceErrorsForNodes(flow.nodes),
  ...getNodeNameReferenceErrorsForEdges(flow.edges),
];

export default validateNodeNameIsNotUsedAsReference;
