import {
  BaseNodeProperty,
  NodeProperty,
  OverviewNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class OverviewLayout extends BaseLayout {
  constructor(node: OverviewNode) {
    super(node);

    const { properties } = node;
    const { isReadOnly } = NodeProperty;
    const { message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addCheckboxField(
        {
          fieldName: isReadOnly,
          label: nodeProperties[isReadOnly].label,
          ...properties[isReadOnly],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default OverviewLayout;
