import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Loader, LoaderColor, LoaderSize } from '@ids-react/loader';

import classes from './FullPageLoader.module.scss';

interface FullPageLoaderProps {
  headerOffset?: boolean;
}

const FullPageLoader: FunctionComponent<FullPageLoaderProps> = ({
  headerOffset,
}: FullPageLoaderProps) => {
  return (
    <div
      className={classNames(classes.FullPageLoader, {
        [classes.HeaderOffset]: headerOffset,
      })}
    >
      <Loader color={LoaderColor.BLUE} size={LoaderSize.LARGEST} />
    </div>
  );
};

export default FullPageLoader;
