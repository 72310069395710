export enum NodeShape {
  BASE = 'BASE',
  MARKER = 'MARKER',
  ICON = 'ICON',
  STATE_MASK = 'STATE_MASK',
  ANCHOR_POINT = 'ANCHOR_POINT',
  COMMENT_ICON = 'COMMENT_ICON',
  ERROR_ICON = 'ERROR_ICON',
  SMALL_NODE_ELEMENT = 'SMALL_NODE_ELEMENT',
  MEDIUM_NODE_ELEMENT = 'MEDIUM_NODE_ELEMENT',
  LARGE_NODE_ELEMENT = 'LARGE_NODE_ELEMENT',
}
