import { Colors } from '@/constants';
import { G6Styles } from '@/G6';
import { NodeShape } from '../nodeShape';
import { NODE_TOTAL_WIDTH } from '../nodeStyles';

import createG6JsxErrorIcon from './createG6JsxErrorIcon';

const ANCHOR_POINT_RADIUS = 19;
const ANCHOR_POINT_LINE_WIDTH = 2;
const ANCHOR_POINT_STROKE = Colors.BLUE_100;
const ANCHOR_PONT_CENTER_RADIUS = 8;
const ANCHOR_PONT_CENTER_FILL = Colors.BLUE_100;

export interface CreateG6JsxStateMaskParams {
  nodeHeight: number;
}

const createG6JsxStateMask = ({
  nodeHeight,
}: CreateG6JsxStateMaskParams): string => `
<rect
  name="${NodeShape.STATE_MASK}"
  style={{
    ${G6Styles.WIDTH}: ${NODE_TOTAL_WIDTH},
    ${G6Styles.HEIGHT}: ${nodeHeight},
    ${G6Styles.MARGIN_TOP}: ${-nodeHeight * 2},
    ${G6Styles.LINE_WIDTH}: 0,
    ${G6Styles.FILL}: 'transparent',
    ${G6Styles.CURSOR}: 'pointer',
  }}
  draggable="true"
>
  <circle
    name="${NodeShape.ANCHOR_POINT}"
    style={{
      ${G6Styles.R}: ${ANCHOR_POINT_RADIUS},
      ${G6Styles.MARGIN_TOP}: ${0},
      ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH / 2},
      ${G6Styles.LINE_WIDTH}: ${ANCHOR_POINT_LINE_WIDTH},
      ${G6Styles.FILL}: '${Colors.BEIGE_100}',
      ${G6Styles.STROKE}: '${ANCHOR_POINT_STROKE}',
      ${G6Styles.CURSOR}: pointer,
    }}
    visible="false"
  >
    <circle
      name="${NodeShape.ANCHOR_POINT}"
      style={{
        ${G6Styles.R}: ${ANCHOR_PONT_CENTER_RADIUS},
        ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH / 2},
        ${G6Styles.FILL}: '${ANCHOR_PONT_CENTER_FILL}',
        ${G6Styles.CURSOR}: pointer,
      }}
      visible="false"
    >
    </circle>
  </circle>

  <circle
    name="${NodeShape.ANCHOR_POINT}"
    style={{
      ${G6Styles.R}: ${ANCHOR_POINT_RADIUS},
      ${G6Styles.MARGIN_TOP}: ${-ANCHOR_POINT_RADIUS * 2 + nodeHeight / 2},
      ${G6Styles.LINE_WIDTH}: ${ANCHOR_POINT_LINE_WIDTH},
      ${G6Styles.FILL}: '${Colors.BEIGE_100}',
      ${G6Styles.STROKE}: '${ANCHOR_POINT_STROKE}',
      ${G6Styles.CURSOR}: pointer,
    }}
    visible="false"
  >
    <circle
      name="${NodeShape.ANCHOR_POINT}"
      style={{
        ${G6Styles.R}: ${ANCHOR_PONT_CENTER_RADIUS},
        ${G6Styles.FILL}: '${ANCHOR_PONT_CENTER_FILL}',
        ${G6Styles.CURSOR}: pointer,
      }}
      visible="false"
    >
    </circle>
  </circle>

  <circle
    name="${NodeShape.ANCHOR_POINT}"
    style={{
      ${G6Styles.R}: ${ANCHOR_POINT_RADIUS},
      ${G6Styles.MARGIN_TOP}: ${-ANCHOR_POINT_RADIUS * 4 + nodeHeight / 2},
      ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH},
      ${G6Styles.LINE_WIDTH}: ${ANCHOR_POINT_LINE_WIDTH},
      ${G6Styles.FILL}: '${Colors.BEIGE_100}',
      ${G6Styles.STROKE}: '${ANCHOR_POINT_STROKE}',
      ${G6Styles.CURSOR}: pointer,
    }}
    visible="false"
  >
    <circle
      name="${NodeShape.ANCHOR_POINT}"
      style={{
        ${G6Styles.R}: ${ANCHOR_PONT_CENTER_RADIUS},
        ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH},
        ${G6Styles.FILL}: '${ANCHOR_PONT_CENTER_FILL}',
        ${G6Styles.CURSOR}: pointer,
      }}
      visible="false"
    >
    </circle>
  </circle>

  <circle
    name="${NodeShape.ANCHOR_POINT}"
    style={{
      ${G6Styles.R}: ${ANCHOR_POINT_RADIUS},
      ${G6Styles.MARGIN_TOP}: ${-ANCHOR_POINT_RADIUS * 6 + nodeHeight},
      ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH / 2},
      ${G6Styles.LINE_WIDTH}: ${ANCHOR_POINT_LINE_WIDTH},
      ${G6Styles.FILL}: '${Colors.BEIGE_100}',
      ${G6Styles.STROKE}: '${ANCHOR_POINT_STROKE}',
      ${G6Styles.CURSOR}: pointer,
    }}
    visible="false"
  >
    <circle
      name="${NodeShape.ANCHOR_POINT}"
      style={{
        ${G6Styles.R}: ${ANCHOR_PONT_CENTER_RADIUS},
        ${G6Styles.MARGIN_LEFT}: ${NODE_TOTAL_WIDTH / 2},
        ${G6Styles.FILL}: '${ANCHOR_PONT_CENTER_FILL}',
        ${G6Styles.CURSOR}: pointer,
      }}
      visible="false"
    >
    </circle>
  </circle>

  ${createG6JsxErrorIcon()}

</rect>
`;

export default createG6JsxStateMask;
