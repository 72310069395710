import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import HtmlErrorPage from '@/components/ErrorPage/errorPage.html?raw';

import './global.scss';

const rootElement = document.getElementById('root');

try {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    rootElement
  );
} catch (_error) {
  document.getElementsByTagName('html')[0].innerHTML = HtmlErrorPage;

  // Requeued, because the imported HTML file will not have its scripts executed
  const reloadHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  };
  document.getElementsByTagName('button')[0].onclick = reloadHandler;
}
