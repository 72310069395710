import { BaseNodeProperty, NodeProperty } from '@riga-claims/atlas-models';

export const nodePropertyTabs = {
  tabOne: 'Content',
  tabTwo: 'Validation',
  tabThree: 'Properties',
};

export const nodePropertiesGroups = {
  customerFacing: 'Customer facing',
  editNode: 'Edit node',
  editAnswers: 'Edit answers',
  inputValidation: 'Input validation',
  identification: 'Identification',
  answersMarked: 'Answers marked',
  summaryOverviewGroupTitle: 'Summary/overview',
  availableDates: 'Available dates',
  timeLimit: 'Time limit',
  validationMessage: 'Validation message',
  accessibility: 'Accessibility',
  modal: 'Modal',
  uploadFailed: 'Upload failed',
  allowedFileMIMETypes: 'Allowed file MIME types',
  listLength: 'List length',
  allowedCurrencies: 'Allowed currencies',
  items: 'Items',
  authenticationFailed: 'Authentication failed',
  decryptOptions: 'Decrypt options',
  noResultsFound: 'No results found',
  dataLogging: 'Personal and sensitive data logging',
  choices: 'Choices',
  totalSum: 'Total sum',
  settings: 'Settings',
  fileValidation: 'File validation',
  claimDetails: 'Claim details',
};

interface PropertyLabel {
  label: BaseNodeProperty | NodeProperty | string;
  placeholder?: string;
}

export const baseNodeProperties: Record<
  BaseNodeProperty,
  { label: string; placeholder?: string; title?: string }
> = {
  [BaseNodeProperty.message]: {
    label: 'Message',
    placeholder: 'Message text to the customer',
  },
  [BaseNodeProperty.variableName]: {
    label: 'Variable (optional)',
    placeholder: 'Add variable name',
  },
  [BaseNodeProperty.answerCanContainPersonalData]: {
    label: 'Answer',
    title: 'Contains personal data',
  },
  [BaseNodeProperty.questionContainsPersonalData]: {
    label: 'Message',
    title: 'Contains personal data',
  },
  [BaseNodeProperty.validationRegex]: {
    label: 'Allowed input types',
    placeholder: 'Write allowed input types here',
  },
  [BaseNodeProperty.validationMessage]: {
    label: 'Validation message',
    placeholder: 'Error message in case the user enters incorrect data',
  },
  [BaseNodeProperty.validationMaxLengthValue]: {
    label: 'Maximum length',
    placeholder: 'Number',
  },
  [BaseNodeProperty.summaryMessage]: {
    label: 'Label in summary/overview',
    placeholder: 'Prefilled from Message to a customer',
  },
  [BaseNodeProperty.excludeFromSummary]: {
    label: 'Exclude from summary/overview',
  },
  [BaseNodeProperty.comment]: {
    label: 'Comment',
    placeholder: 'Add comment to this node',
  },
  [BaseNodeProperty.sectionId]: {
    label: 'Section Id',
    placeholder: 'Section Id',
  },
  [BaseNodeProperty.defaultValue]: {
    label: 'Default Value',
    placeholder: 'Default Value',
  },
  [BaseNodeProperty.isRequired]: {
    label: 'Is answer required?',
    title: 'Answer is required',
  },
  [BaseNodeProperty.defaultValidationMessage]: {
    label: 'Default validation message',
    placeholder: 'Default validation message',
  },
  [BaseNodeProperty.isRequiredValidationMessage]: {
    label: 'Required field validation message',
    placeholder: 'Required field validation message',
  },
};

export const nodeProperties: Record<string, PropertyLabel> = {
  [NodeProperty.placeholder]: {
    label: 'Placeholder',
    placeholder: 'Type input field placeholder text',
  },
  [NodeProperty.removeWhiteSpaces]: {
    label: 'Remove white space',
  },
  [NodeProperty.toUpperCase]: {
    label: 'To uppercase',
  },
  [NodeProperty.datePreset]: {
    label: 'Date format',
  },
  [NodeProperty.confirmButton]: {
    label: 'Confirm button label',
    placeholder: 'Add label to confirm button',
  },
  [NodeProperty.minimumPeriod]: {
    label: 'Period start date',
    placeholder: 'Add period start date',
  },
  [NodeProperty.maximumPeriod]: {
    label: 'Period end date',
    placeholder: 'Add period end date',
  },
  [NodeProperty.dateAvailabilityType]: {
    label: 'Dates available',
  },
  [NodeProperty.shouldNotExeedNow]: {
    label: 'Should not exceed now',
  },
  [NodeProperty.exeedsNowMessage]: {
    label: 'Message in case of incorrect time (optional)',
    placeholder: 'Type an error message for customer',
  },
  [NodeProperty.date]: {
    label: 'Date',
    placeholder: 'Add date here',
  },
  [NodeProperty.dateFormat]: {
    label: 'Date format',
  },
  [NodeProperty.timeZoneId]: {
    label: 'Time zone id',
  },
  [NodeProperty.submitHint]: {
    label: 'Submit hint',
    placeholder: 'What is it?',
  },
  [NodeProperty.ratingHint]: {
    label: 'Rating hint',
    placeholder: 'What is it?',
  },
  [NodeProperty.designType]: {
    label: 'Design style',
  },
  [NodeProperty.conditionalDesignType]: {
    label: 'Conditional design type',
    placeholder: 'Define conditional design type',
  },
  [NodeProperty.title]: {
    label: 'Screen reader title',
    placeholder: 'Screen reader title',
  },
  [NodeProperty.language]: {
    label: 'Screen reader language',
  },
  [NodeProperty.summaryDefinition]: {
    label: 'Summary definition node',
    placeholder: 'Summary definition node',
  },
  [NodeProperty.fileName]: {
    label: 'File name',
  },
  [NodeProperty.pageHeightInMillimeters]: {
    label: 'Page hight, mm',
  },
  [NodeProperty.loginButton]: {
    label: 'Login button',
    placeholder: 'Add login button label',
  },
  [NodeProperty.loginMobileButton]: {
    label: 'Login button for mobile',
    placeholder: 'Login button for mobile',
  },
  [NodeProperty.encryptedValue]: {
    label: 'Value to decrypt',
    placeholder: 'Encrypted value',
  },
  [NodeProperty.frontenSummaryDefinition]: {
    label: 'Fronten summary definition',
    placeholder: 'Fronten summary definition here',
  },
  [NodeProperty.frontenHtmlFileName]: {
    label: 'Fronten HTML file name',
    placeholder: 'File name here',
  },
  [NodeProperty.frontenBusinessArea]: {
    label: 'Fronten business area',
    placeholder: 'Choose business area',
  },
  [NodeProperty.frontenCategory]: {
    label: 'Fronten category',
    placeholder: 'Choose category',
  },
  [NodeProperty.frontenDocumentType]: {
    label: 'Fronten document type',
    placeholder: 'Choose document type',
  },
  [NodeProperty.frontenQueue]: {
    label: 'Fronten queue',
    placeholder: 'Choose queue',
  },
  [NodeProperty.frontenClaimHandlerId]: {
    label: 'Fronten claim handler id',
    placeholder: 'Choose claim handler id',
  },
  [NodeProperty.businessArea]: {
    label: 'Business area',
    placeholder: 'Choose business area',
  },
  [NodeProperty.lineOfBusiness]: {
    label: 'Line of business',
    placeholder: 'Choose line of business',
  },
  [NodeProperty.insuredObject]: {
    label: 'Insured object',
    placeholder: 'Choose object',
  },
  [NodeProperty.customerNumber]: {
    label: 'Customer number',
    placeholder: 'Customer number here',
  },
  [NodeProperty.policyNumber]: {
    label: 'Policy number',
    placeholder: 'Policy number here',
  },
  [NodeProperty.claimNumber]: {
    label: 'Claim number',
    placeholder: 'Claim number here',
  },
  [NodeProperty.claimType]: {
    label: 'Claim type',
    placeholder: 'Choose claim type',
  },
  [NodeProperty.parentType]: {
    label: 'Parent type',
    placeholder: 'Choose parent type',
  },
  [NodeProperty.claimReportName]: {
    label: 'Claim report name',
    placeholder: 'Claim report name here',
  },
  [NodeProperty.country]: {
    label: 'Country',
    placeholder: 'Choose country',
  },
  [NodeProperty.customerEmail]: {
    label: 'Customer email',
    placeholder: 'Customer email here',
  },
  [NodeProperty.customerName]: {
    label: 'Customer name',
    placeholder: 'Customer name here',
  },
  [NodeProperty.customerPhone]: {
    label: 'Customer phone',
    placeholder: 'Customer phone here',
  },
  [NodeProperty.incidentDate]: {
    label: 'Incident date',
    placeholder: 'Incident date here',
  },
  [NodeProperty.stargateTemplate]: {
    label: 'Stargate template',
    placeholder: 'Stargate template here',
  },
  [NodeProperty.vehicleRegistrationNumber]: {
    label: 'Vehicle registration number',
    placeholder: 'Vehicle registration number here',
  },
  [NodeProperty.headerText]: {
    label: 'Modal window title',
    placeholder: 'Add modal window title e.g. File upload',
  },
  [NodeProperty.uploadFilesButtonText]: {
    label: 'Upload files button text',
    placeholder: 'Add upload files button text',
  },
  [NodeProperty.browseFilesButtonText]: {
    label: 'Browse files button text',
    placeholder: 'Add browse files button text',
  },
  [NodeProperty.dropZoneContainerText]: {
    label: 'Drop zone file upload text',
    placeholder: 'Add drop zone file upload text',
  },
  [NodeProperty.uploadInProgressButtonText]: {
    label: 'Upload in progress text',
    placeholder: 'Message when file is uploading',
  },
  [NodeProperty.uploadFailedErrorMessage]: {
    label: 'Upload failed error message',
    placeholder: 'Add error message for the customer',
  },
  [NodeProperty.allowedFileExtensions]: {
    label: 'Allowed file extensions',
  },
  [NodeProperty.fileTypeErrorMessage]: {
    label: 'Wrong file type error message',
    placeholder: 'Add error message for the customer',
  },
  [NodeProperty.maxFileSizeInBytes]: {
    label: 'Maximum file size (bytes)',
    placeholder: 'Number here',
  },
  [NodeProperty.fileSizeErrorMessage]: {
    label: 'File too large error message',
    placeholder: 'Add error message for the customer',
  },
  [NodeProperty.maxFileCount]: {
    label: 'Maximum file count',
    placeholder: 'Number here',
  },
  [NodeProperty.fileCountErrorMessage]: {
    label: 'Too many files error message',
    placeholder: 'Add error message for the customer',
  },
  [NodeProperty.accessId]: {
    label: 'Access id',
    placeholder: 'Access id here',
  },
  [NodeProperty.claimNumberRegex]: {
    label: 'Claim number regex',
    placeholder: 'Claim number regex here',
  },
  [NodeProperty.aclName]: {
    label: 'ACL name',
    placeholder: 'ACL name here',
  },
  [NodeProperty.contentOwner]: {
    label: 'Content owner',
  },
  [NodeProperty.customerType]: {
    label: 'Customer type',
  },
  [NodeProperty.policyType]: {
    label: 'Policy type',
  },
  [NodeProperty.carouselNodeName]: {
    label: 'Carousel variable',
    placeholder: 'Carousel variable name',
  },
  [NodeProperty.column1]: {
    label: 'Column 1',
    placeholder: 'Column 1 items',
  },
  [NodeProperty.column2]: {
    label: 'Column 2',
    placeholder: 'Column 2 items',
  },
  [NodeProperty.column3]: {
    label: 'Column 3',
    placeholder: 'Column 3 items',
  },
  [NodeProperty.noItemsInListMessage]: {
    label: 'No items in a list message',
    placeholder: 'Message shown when no items are added',
  },
  [NodeProperty.addNewButtonText]: {
    label: 'Add item button label (TellUs only)',
    placeholder: 'Add item button label (default: Add item)',
  },
  [NodeProperty.removeButtonText]: {
    label: 'Remove button label',
    placeholder: 'Remove item button label',
  },
  [NodeProperty.undoRemoveButtonText]: {
    label: 'Undo button label',
    placeholder: 'Undo button label',
  },
  [NodeProperty.minItemCount]: {
    label: 'Minimum number of items',
    placeholder: 'Number of items, (default: 1)',
  },
  [NodeProperty.minCountErrorMessage]: {
    label: 'Minimum number of items error message',
    placeholder: 'Error message in case number of items are less than minimum',
  },
  [NodeProperty.maxItemCount]: {
    label: 'Maximum number of items',
    placeholder: 'Number of items',
  },
  [NodeProperty.maxCountErrorMessage]: {
    label: 'Maximum number of items error message',
    placeholder: 'Error message in case number of items exceed maximum',
  },
  [NodeProperty.addItemButtons]: {
    label: 'Connections that add more items to the list',
    placeholder:
      'Comma-separated list of connection orders. Default is first connection',
  },
  [NodeProperty.continueButtons]: {
    label: 'Connections that exit item list',
    placeholder:
      'Comma-separated list of connection orders. Default is last connection',
  },
  [NodeProperty.showTotalSum]: {
    label: 'Show total sum',
  },
  [NodeProperty.totalSumLabel]: {
    label: 'Label for total sum',
    placeholder: 'Label to show before total sum value',
  },
  [NodeProperty.totalSumUnits]: {
    label: 'Units for total sum',
    placeholder: 'Units (e.g. currency symbol) to show after total sum value',
  },
  [NodeProperty.defaultCurrency]: {
    label: 'Default currency',
  },
  [NodeProperty.placeholder]: {
    label: 'Placeholder (optional)',
    placeholder: 'Add placeholder for text input field',
  },
  [NodeProperty.summaryTemplateData]: {
    label: 'Open summary editor',
  },
  [NodeProperty.listItemDisplayPattern]: {
    label: 'List item display pattern',
    placeholder: 'Enter pattern',
  },
  [NodeProperty.sumVariableName]: {
    label: 'Sum variable name',
    placeholder: 'Variable name for calculating sum',
  },
  embeddedFile: {
    label: 'File embedded',
    placeholder: 'Choose file to embed',
  },
  summaryTitle: {
    label: 'Title',
  },
  authenticationErrorMessage: {
    label: 'Error message',
    placeholder:
      'Add error message in case of failure to authenticate customer',
  },
  resultErrorMessage: {
    label: 'Error message',
    placeholder: 'Add error message for customer in case no results are found',
  },
  [NodeProperty.countryCode]: {
    label: 'Country code',
    placeholder: 'Country code (No, Dk, Se or Fi)',
  },
  [NodeProperty.authenticationDataType]: {
    label: 'Authentication data type',
  },
  [NodeProperty.ssnCountryCode]: {
    label: 'Country code',
  },
  [NodeProperty.statisticsEventType]: {
    label: 'Statistics event type',
  },
  [NodeProperty.recordNodeValueBag]: {
    label: 'Add node data to value bag',
    placeholder: 'Add node data to value bag',
  },
  [NodeProperty.displayRecorded]: {
    label: 'Show in debug statistics data',
  },
  [NodeProperty.dataSource]: {
    label: 'Data source',
    placeholder: 'Data source JSON',
  },
  longListTitle: {
    label: 'Title',
    placeholder: 'Modal title text',
  },
  [NodeProperty.maxItemsToSelect]: {
    label: 'Max selectable items',
    placeholder: 'Number here',
  },
  [NodeProperty.maxItemsToSelectErrorMessage]: {
    label: 'Exceeding max selectable items error message',
    placeholder: 'Error message to the customer',
  },
  [NodeProperty.openButtonText]: {
    label: 'Open list button label',
    placeholder: 'Button label here',
  },
  [NodeProperty.defaultButton]: {
    label: 'Button label',
    placeholder: 'Button label here',
  },
  [NodeProperty.allowAnyValue]: {
    label: 'Allow any value',
    placeholder: 'Allow any value',
  },
  [NodeProperty.defaultCountry]: {
    label: 'Default country',
  },
  [NodeProperty.legalNumber]: {
    label: 'Legal number',
    placeholder: 'Legal number here',
  },
  [NodeProperty.bankAccountNumber]: {
    label: 'Bank account',
    placeholder: 'Bank account number here',
  },
  [NodeProperty.bankAccountOwnerSummaryMessage]: {
    label: 'Bank account owner summary message',
    placeholder: 'message here',
  },
  [NodeProperty.bankAccountOwner]: {
    label: 'Bank account owner',
    placeholder: 'Bank account owner here',
  },
  [NodeProperty.notBankAccountOwnerSummaryMessage]: {
    label: 'Not bank account owner summary message',
    placeholder: 'message here',
  },
  [NodeProperty.bankAccountNotVerifiedSummaryMessage]: {
    label: 'Bank account not verified message',
    placeholder: 'message here',
  },
  [NodeProperty.product]: {
    label: 'Product',
    placeholder: 'Product',
  },
  [NodeProperty.continueButtonText]: {
    label: 'Continue button',
    placeholder: 'Continue button text',
  },
  [NodeProperty.hideNavigation]: {
    label: 'Hide side navigation',
  },
  [NodeProperty.classifierType]: {
    label: 'Classifier type',
  },
  [NodeProperty.reporterId]: {
    label: 'Reporter (User) ID',
  },
  [NodeProperty.draftId]: {
    label: 'Conversation draft ID',
  },
  [NodeProperty.originalConversationId]: {
    label: 'Original conversation ID',
  },
  [NodeProperty.firstNodeId]: {
    label: 'ID of the first node to load from draft',
  },
  [NodeProperty.lastNodeId]: {
    label: 'ID of the last node to load from draft',
  },
  [NodeProperty.isDeadEnd]: {
    label: 'Is dead end section',
  },
  [NodeProperty.splitChar]: {
    label: 'Split Character (to join choice items)',
  },
  [NodeProperty.accessId]: {
    label: 'Access Id *',
    placeholder: 'Access Id',
  },
  [NodeProperty.contentOwner]: {
    label: 'Content owner *',
    placeholder: 'PRI, COM, IND, FIN',
  },
  [NodeProperty.country]: {
    label: 'Country *',
    placeholder: 'NO, DK, SE, FI',
  },
  [NodeProperty.customerType]: {
    label: 'Customer type *',
    placeholder: 'MCDB, LH, WP, SAJ, FK, FX, CG, CCID',
  },
  [NodeProperty.lineOfBusiness]: {
    label: 'Line of business *',
    placeholder: 'motor insurance, property insurance etc.',
  },
  [NodeProperty.policyType]: {
    label: 'Policy type *',
    placeholder: 'AGRM, PLCY, LH, WP, FX, CG, NOR, PPA, SMPA, PRUS',
  },
  [NodeProperty.uploadDuringSubmission]: {
    label: 'Enable in Tellus',
  },
  [NodeProperty.aclName]: {
    label: 'Acl name',
    placeholder: 'Acl name',
  },
  [NodeProperty.claimNumberRegex]: {
    label: 'Claim Number Regex',
    placeholder: 'Claim Number Regex',
  },
  [NodeProperty.fileEmptyErrorMessage]: {
    label: 'File Empty Error Message',
    placeholder: 'File Empty Error Message',
  },
  [NodeProperty.isReadOnly]: {
    label: 'Is Read-Only',
    placeholder: 'Is Read-Only',
  },
  [NodeProperty.brand]: {
    label: 'Brand',
    placeholder: 'Brand',
  },
  [NodeProperty.scenario]: {
    label: 'Scenario',
    placeholder: 'Scenario',
  },
  [NodeProperty.event]: {
    label: 'Event',
    placeholder: 'Event',
  },
  [NodeProperty.insuranceObject]: {
    label: 'Insurance object',
    placeholder: 'Insurance object',
  },
};
