import { nanoid } from 'nanoid';
import GroupData from './GroupData';

export const remainingProperties = 'Remaining properties';
export const customProperties = 'Custom properties';

class TabData {
  private groups: GroupData[] = [];
  public key = nanoid();

  public addGroup = (label?: string, collapsedByDefault?: boolean): GroupData =>
    this.groups[this.groups.push(new GroupData(label, collapsedByDefault)) - 1];

  public getGroups = (): GroupData[] => this.groups;

  public getOrAddLastGroup = (): GroupData =>
    this.groups.length ? this.groups[this.groups.length - 1] : this.addGroup();

  public getOrAddCustomPropertyGroup = (): GroupData =>
    this.groups.find((group) => group.label === customProperties) ||
    this.addGroup(customProperties);

  public getOrAddRemainingFieldGroup = (): GroupData =>
    this.groups.find((group) => group.label === remainingProperties) ||
    this.addGroup(remainingProperties, true);
}

export default TabData;
