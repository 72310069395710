import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlowGraph } from '@/api/types';

export interface FlowState {
  flowGraph: FlowGraph | null;
}

export const initialFlowState: FlowState = {
  flowGraph: null,
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState: initialFlowState,
  reducers: {
    setFlowGraph: (state, action: PayloadAction<FlowGraph | null>) => {
      state.flowGraph = action.payload;
    },
    resetFlowState: () => initialFlowState,
  },
});

export const { setFlowGraph, resetFlowState } = flowSlice.actions;

export default flowSlice.reducer;
