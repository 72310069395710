import {
  BaseNodeProperty,
  Finland_PersonalInjury_GetInsuredPeopleNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class GetInsuredPeopleLayout extends BaseLayout {
  constructor(node: Finland_PersonalInjury_GetInsuredPeopleNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;
    const { ssn } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab.addGroup().addTextField(
      {
        fieldName: ssn,
        label: 'SSN',
        placeholder: 'SSN value here',
        ...properties[ssn],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetInsuredPeopleLayout;
