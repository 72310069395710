import {
  BaseNodeProperty,
  Finland_PersonalInjury_ShowInsuredPeopleNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class InsuredPeopleLayout extends BaseLayout {
  constructor(node: Finland_PersonalInjury_ShowInsuredPeopleNode) {
    super(node);

    const { properties } = node;
    const { insuredPeople, availableVariables } = NodeProperty;
    const { variableName, message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties.message.label,
          placeholder: baseNodeProperties.message.placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup()
      .addTextField(
        {
          fieldName: insuredPeople,
          label: 'Insured people',
          placeholder: '[XXXXX.insuredPeople]',
          ...properties[insuredPeople],
        },
        this.mappedFields
      )
      .addDropdownField(
        {
          fieldName: availableVariables,
          label: 'Available variables',
          ...properties[availableVariables],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InsuredPeopleLayout;
