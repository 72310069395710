/**
 * Adds `<script>` tags to the page body from a CDN or the public folder
 * Returns a promise that resolves when the script triggers the `load` event
 * or rejects if the script triggers the `error` event
 *
 * @param {string} src Link to the script file
 * @param {boolean} [defer=false] Specify `<script>` attribute `defer`
 * @return {*}  {Promise<void>} Void or error if `error` event triggered
 */
const loadScript = (src: string, defer = false): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = defer;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (error) => {
      reject(error);
    });
    document.body.appendChild(script);
  });
};

export default loadScript;
