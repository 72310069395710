import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { sanitizeAppInsightsUserName } from '@/utils';

let appInsights: ApplicationInsights;
const reactPlugin = new ReactPlugin();

export const initializeAppInsights = ({
  instrumentationKey,
  user,
}: {
  instrumentationKey: string;
  user: string;
}): void => {
  const browserHistory = createBrowserHistory({ window });
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();

  registerAppInsightsUserContext(user);
};

export const registerAppInsightsUserContext = (user: string): void =>
  appInsights.setAuthenticatedUserContext(sanitizeAppInsightsUserName(user));

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin for docs on usage
export { reactPlugin, appInsights };
