import {
  BaseNodeProperty,
  Finland_HealthNavigator_ClaimLiabilitySearchNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class VerifyCoverageLayout extends BaseLayout {
  constructor(node: Finland_HealthNavigator_ClaimLiabilitySearchNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;
    const {
      incidentDate,
      incidentType,
      ssn,
      symptomCodes,
      accidentIncidentCause,
      accidentInjuryType,
      accidentBodyPart,
      accidentHasOccurredInCompetition,
      accidentWasAssault,
      accidentHasAlcoholOrDrugsInvolved,
      accidentCircumstance,
      accidentHasEarlierSymptoms,
      accidentIsWideAccident,
    } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup()
      .addTextField(
        {
          fieldName: incidentDate,
          label: 'Incident date',
          placeholder: 'Incident date value here',
          ...properties[incidentDate],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: incidentType,
          label: 'Incident type',
          placeholder: 'Incident type value here',
          ...properties[incidentType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: ssn,
          label: 'SSN',
          placeholder: 'SSN value here',
          ...properties[ssn],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: symptomCodes,
          label: 'Symptom codes',
          placeholder: 'Symptom codes value here',
          ...properties[symptomCodes],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentIncidentCause,
          label: 'Accident Incident Cause',
          placeholder: 'Accident Incident cause value here',
          ...properties[accidentIncidentCause],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentInjuryType,
          label: 'Accident Injury Type',
          placeholder: 'Accident Injury Type value here',
          ...properties[accidentInjuryType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentBodyPart,
          label: 'Accident Body Part',
          placeholder: 'Accident Body Part value here',
          ...properties[accidentBodyPart],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentHasOccurredInCompetition,
          label: 'Accident Has Occurred In Competition',
          placeholder: 'Accident Has Occurred In Competition value here',
          ...properties[accidentHasOccurredInCompetition],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentWasAssault,
          label: 'Accident Was Assault',
          placeholder: 'Accident Was Assault value here',
          ...properties[accidentWasAssault],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentHasAlcoholOrDrugsInvolved,
          label: 'Accident Has Alcohol Or Drugs Involved',
          placeholder: 'Accident Has Alcohol Or Drugs Involved value here',
          ...properties[accidentHasAlcoholOrDrugsInvolved],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentCircumstance,
          label: 'Accident Circumstance',
          placeholder: 'Accident Circumstance value here',
          ...properties[accidentCircumstance],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentHasEarlierSymptoms,
          label: 'Accident Has Earlier Symptoms',
          placeholder: 'Accident Has Earlier Symptoms value here',
          ...properties[accidentHasEarlierSymptoms],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: accidentIsWideAccident,
          label: 'Accident Is Wide Accident',
          placeholder: 'Accident Is Wide Accident value here',
          ...properties[accidentIsWideAccident],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default VerifyCoverageLayout;
