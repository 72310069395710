import { CancelToken } from 'axios';

import { ApiResponse, AuthorizationApiMethod, AuthorizedUser } from '../types';
import { callApi } from '../api';

export const getAuthorizedUser = (params?: {
  cancelToken?: CancelToken;
}): Promise<ApiResponse<AuthorizedUser>> =>
  callApi({
    url: AuthorizationApiMethod.GET_AUTHORIZED_USER,
    cancelToken: params?.cancelToken,
    baseURL: '/',
  });
