import { FunctionComponent, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { parse, ParsedQuery } from 'query-string';

import { AppRoutes } from '@/routes';

const HomePage = lazy(() => import('@/components/HomePage/HomePage'));
const BoardPage = lazy(() => import('@/components/BoardPage/BoardPage'));
const AccessManagementPage = lazy(
  () => import('@/components/AccessManagementPage/AccessManagementPage')
);
const MigrationPage = lazy(
  () => import('@/components/MigrationPage/MigrationPage')
);
const NodeHelperPage = lazy(
  () => import('@/components/NodeHelperPage/NodeHelperPage')
);
const Statistics = lazy(() => import('@/components/Statistics/Statistics'));
const ConversationLogs = lazy(
  () => import('@/components/ConversationLogs/ConversationLogs')
);
const SearchConversation = lazy(
  () =>
    import(
      '@/components/ConversationLogs/SearchConversation/SearchConversation'
    )
);

const Pages: FunctionComponent = () => {
  const { error, error_description: errorDescription }: ParsedQuery = parse(
    window.location.hash
  );

  if (error) {
    throw new Error(`${error as string}: ${errorDescription as string}`);
  }

  return (
    <Routes>
      <Route path={AppRoutes.ROOT} element={<HomePage />} />
      <Route path={`${AppRoutes.HOME}/*`} element={<HomePage />} />
      <Route path={`${AppRoutes.HOME}/:tenantId/*`} element={<HomePage />} />
      <Route
        path={AppRoutes.CONVERSATION_LOGS}
        element={<ConversationLogs />}
      />
      <Route
        path={AppRoutes.CONVERSATION_LOGS_SEARCH_CONVERSATION}
        element={<SearchConversation />}
      />
      <Route path={AppRoutes.STATISTICS} element={<Statistics />} />
      <Route
        path={`${AppRoutes.BOARD}/:tenantId/:fileName`}
        element={<BoardPage />}
      />
      <Route
        path={AppRoutes.ACCESS_MANAGEMENT}
        element={<AccessManagementPage />}
      />
      <Route
        path={`${AppRoutes.ACCESS_MANAGEMENT}/:tenantId`}
        element={<AccessManagementPage />}
      />
      <Route path={AppRoutes.MIGRATION} element={<MigrationPage />} />
      <Route
        path={`${AppRoutes.MIGRATION}/:tenantId`}
        element={<MigrationPage />}
      />
      <Route path={AppRoutes.NODE_HELPER} element={<NodeHelperPage />} />
      <Route
        path={AppRoutes.NOT_FOUND}
        element={<Navigate to={AppRoutes.ROOT} />}
      />
    </Routes>
  );
};

export default Pages;
