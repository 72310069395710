export enum AppRoutes {
  ROOT = '/',
  BOARD = '/board',
  SIGN_OUT = '/api/auth/sign-out',
  NOT_FOUND = '*',
  ACCESS_MANAGEMENT = '/access-management',
  MIGRATION = '/migration',
  NODE_HELPER = '/node-helper',
  HOME = '/home',
  AUTHORIZE = '/authorize',
  CONVERSATION_LOGS = '/conversation-logs',
  CONVERSATION_LOGS_SEARCH_CONVERSATION = '/conversation-logs/search-conversation',
  STATISTICS = '/statistics',
  TOOLS = '/tools',
}
