import { configureStore, Reducer } from '@reduxjs/toolkit';
import undoable, { StateWithHistory } from 'redux-undo';

import flowReducer, { FlowState } from './flow/flowSlice';
import globalReducer from './global/globalSlice';
import errorReducer from './error/errorSlice';
import authReducer from './auth/authSlice';

export const reducer = {
  flow: undoable(flowReducer, {
    ignoreInitialState: true,
    limit: 100,
  }) as Reducer<StateWithHistory<FlowState>>,
  global: globalReducer,
  error: errorReducer,
  auth: authReducer,
};

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
