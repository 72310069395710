import { BaseNodeProperty, SplitterNode } from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SplitterLayout extends BaseLayout {
  constructor(node: SplitterNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SplitterLayout;
