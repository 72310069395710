import {
  BaseNodeProperty,
  InputTimeNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class InputTimeLayout extends BaseLayout {
  constructor(node: InputTimeNode) {
    super(node);

    const { properties } = node;
    const {
      shouldNotExeedNow,
      exeedsNowMessage,
      date,
      dateFormat,
      timeZoneId,
    } = NodeProperty;
    const { message, variableName } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.timeLimit)
      .addCheckboxField(
        {
          fieldName: shouldNotExeedNow,
          label: nodeProperties[shouldNotExeedNow].label,
          ...properties[shouldNotExeedNow],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: exeedsNowMessage,
          label: nodeProperties[exeedsNowMessage].label,
          placeholder: nodeProperties[exeedsNowMessage].placeholder,
          ...properties[exeedsNowMessage],
        },
        this.mappedFields
      );

    this.propertiesTab
      .addGroup()
      .addTextField(
        {
          fieldName: date,
          label: nodeProperties[date].label,
          placeholder: nodeProperties[date].placeholder,
          ...properties[date],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: dateFormat,
          label: nodeProperties[dateFormat].label,
          ...properties[dateFormat],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: timeZoneId,
          label: nodeProperties[timeZoneId].label,
          ...properties[timeZoneId],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputTimeLayout;
