import {
  BaseNodeProperty,
  Denmark_PersonalInjury_GetUserPoliciesNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
  nodeProperties,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class DenmarkPersonalInjuryGetPoliciesLayout extends BaseLayout {
  constructor(node: Denmark_PersonalInjury_GetUserPoliciesNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { incidentDate } = NodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.customerFacing).addTextField(
      {
        fieldName: incidentDate,
        label: nodeProperties[incidentDate].label,
        placeholder: nodeProperties[incidentDate].placeholder,
        ...properties[incidentDate],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default DenmarkPersonalInjuryGetPoliciesLayout;
