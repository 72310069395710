import {
  NodeProperty,
  BaseNodeProperty,
  RatingStarsNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class RatingStarsLayout extends BaseLayout {
  constructor(node: RatingStarsNode) {
    super(node);

    const { properties } = node;
    const { submitHint, ratingHint } = NodeProperty;
    const { message, variableName } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.accessibility)
      .addTextField(
        {
          fieldName: submitHint,
          label: nodeProperties[submitHint].label,
          placeholder: nodeProperties[submitHint].placeholder,
          ...properties[submitHint],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: ratingHint,
          label: nodeProperties[ratingHint].label,
          placeholder: nodeProperties[ratingHint].placeholder,
          ...properties[ratingHint],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default RatingStarsLayout;
