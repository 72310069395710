import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ICustomProperties } from '@microsoft/applicationinsights-web';

const useTrackException = (): ((
  error: Error,
  customProperties?: ICustomProperties
) => void) => {
  const appInsights = useAppInsightsContext();

  return (error: Error, customProperties) => {
    appInsights.trackException({ exception: error }, customProperties);
  };
};

export default useTrackException;
