import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  withAITracking,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

import { store } from '@/store';
import { getConfig } from '@/utils';
import { EditorConfigurationKeys } from '@/configuration/types';

import { getAuthorizedUser } from './api/authorization/authorizationMethods';

import { initializeAppInsights, reactPlugin } from './appInsights/AppInsights';
import WithLaunchDarkly from '@/components/HOC/WithLaunchDarkly/WithLaunchDarkly';
import ErrorPage from '@/components/ErrorPage/ErrorPage';
import FullPageLoader from './components/Common/FullPageLoader/FullPageLoader';
import Pages from '@/Pages';
import { useCallApi } from './hooks';
import { setUser } from './store/auth/authSlice';

const App: React.FunctionComponent = () => {
  const [appInsightsLoaded, setAppInsightsLoaded] = useState(false);

  const {
    makeRequest: getUser,
    requestData: authorizedUser,
    isRequestSuccessful,
  } = useCallApi(getAuthorizedUser);

  useEffect(() => {
    void getUser();
  }, []);

  useEffect(() => {
    if (isRequestSuccessful) {
      if (authorizedUser) {
        store.dispatch(setUser(authorizedUser));

        initializeAppInsights({
          instrumentationKey: getConfig(
            EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY
          ),
          user: authorizedUser.email,
        });
        setAppInsightsLoaded(true);
      } else {
        //window.location instead of history to navigate "out of react scope" so that aspNet controller receives this redirect
        window.location.replace(
          `/auth/login?redirectUri=${encodeURIComponent(
            window.location.pathname + window.location.search
          )}`
        );
      }
    }
  }, [isRequestSuccessful, authorizedUser]);

  return (
    <Suspense fallback={<FullPageLoader />}>
      <Provider store={store}>
        {appInsightsLoaded ? (
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary
              onError={() => <ErrorPage />}
              appInsights={reactPlugin}
            >
              <WithLaunchDarkly
                launchDarklyClientSideKey={getConfig(
                  EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY
                )}
                launchDarklyUserKey={getConfig(
                  EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY
                )}
                launchDarklyApplicationName={getConfig(
                  EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME
                )}
                launchDarklyEnvironment={getConfig(
                  EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT
                )}
              >
                <BrowserRouter>
                  <Pages />
                </BrowserRouter>
              </WithLaunchDarkly>
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        ) : (
          <FullPageLoader />
        )}
      </Provider>
    </Suspense>
  );
};

export default withAITracking(reactPlugin, App);
