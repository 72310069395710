import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ExtendedOnBlur, InputField, InputType } from '@riga-claims/storybook';

import { JSXElementRenderProps } from '@/nodeVisualConfigurations';

import classes from './InputValidationRadioGroup.module.scss';

export enum ValidationRegex {
  ALL = '',
  NUMBERS = '^[0-9]+$',
  TEXT = '^[a-zA-Z]*$',
  DATE = '^([0]?[0-9]|[12][0-9]|[3][01])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$',
}

export enum RadioButtonLabels {
  ALL = 'All',
  NUMBERS = 'Number',
  TEXT = 'Text',
  DATE = 'Date',
  CUSTOM = 'Custom',
}

enum RadioGroupIds {
  ALL = 'radio-all',
  NUMBERS = 'radio-numbers',
  TEXT = 'radio-text',
  DATE = 'radio-date',
  CUSTOM = 'radio-custom',
}

const InputValidationRadioGroup = ({
  debouncedUpdateFlowGraphStore,
  graph,
  selectedNodeParams,
}: JSXElementRenderProps): JSX.Element => {
  const [currentValidationRegex, setCurrentValidationRegex] = useState(
    selectedNodeParams.properties.validationRegex?.value || ''
  );

  useEffect(() => {
    if (selectedNodeParams.properties.validationRegex?.value !== undefined) {
      setCurrentValidationRegex(
        selectedNodeParams.properties.validationRegex.value
      );
    }
  }, [selectedNodeParams.properties.validationRegex?.value]);

  const validationRegex =
    selectedNodeParams.properties.validationRegex?.value || '';

  const isPredefinedRegex = Object.values<string | undefined>(
    ValidationRegex
  ).includes(validationRegex);

  const handleRadioButtonClick = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCurrentValidationRegex(newValue);

    graph.updateItem(selectedNodeParams.id, {
      properties: {
        validationRegex: { value: newValue },
      },
    });

    debouncedUpdateFlowGraphStore({ updateInstantly: true });
  };

  const updateCustomRegex: ExtendedOnBlur = ({ newValue, hasValueChanged }) => {
    if (hasValueChanged) {
      graph.updateItem(selectedNodeParams.id, {
        properties: {
          validationRegex: { value: newValue },
        },
      });

      debouncedUpdateFlowGraphStore({ updateInstantly: true });
    }
  };

  return (
    <div className={classes.RadioGroup}>
      <div>
        <input
          type="radio"
          id={RadioGroupIds.ALL}
          name="radios"
          value={ValidationRegex.ALL}
          onChange={handleRadioButtonClick}
          checked={validationRegex === ValidationRegex.ALL}
        />
        <label htmlFor={RadioGroupIds.ALL}>All</label>
      </div>
      <div>
        <input
          type="radio"
          id={RadioGroupIds.NUMBERS}
          name="radios"
          value={ValidationRegex.NUMBERS}
          onChange={handleRadioButtonClick}
          checked={validationRegex === ValidationRegex.NUMBERS}
        />
        <label htmlFor={RadioGroupIds.NUMBERS}>Number</label>
      </div>
      <div>
        <input
          type="radio"
          id={RadioGroupIds.TEXT}
          name="radios"
          value={ValidationRegex.TEXT}
          onChange={handleRadioButtonClick}
          checked={validationRegex === ValidationRegex.TEXT}
        />
        <label htmlFor={RadioGroupIds.TEXT}>Text</label>
      </div>
      <div>
        <input
          type="radio"
          id={RadioGroupIds.DATE}
          name="radios"
          value={ValidationRegex.DATE}
          onChange={handleRadioButtonClick}
          checked={validationRegex === ValidationRegex.DATE}
        />
        <label htmlFor={RadioGroupIds.DATE}>Date</label>
      </div>
      <div
        className={classNames(classes.RadioItem, classes.CustomInputWrapper)}
      >
        <input
          type="radio"
          id={RadioGroupIds.CUSTOM}
          name="radios"
          onChange={handleRadioButtonClick}
          checked={!isPredefinedRegex}
        />
        <label htmlFor={RadioGroupIds.CUSTOM}>Custom</label>
        <div className={classes.CustomInput}>
          <InputField
            id="validation-regex"
            name="validation-regex"
            type={InputType.TEXT}
            onChange={(event) => setCurrentValidationRegex(event.target.value)}
            extendedOnBlur={updateCustomRegex}
            value={currentValidationRegex}
            compact
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  );
};

export default InputValidationRadioGroup;
