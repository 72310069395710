import {
  BaseNodeProperty,
  GetPrefillStorageNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class GetPrefillStorageLayout extends BaseLayout {
  constructor(node: GetPrefillStorageNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { storageId } = NodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.customerFacing).addTextField(
      {
        fieldName: storageId,
        label: 'Retrieve by storage Id',
        ...properties[storageId],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetPrefillStorageLayout;
