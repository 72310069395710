import { NodeProperty, Common_SendDataNode } from '@riga-claims/atlas-models';

import { nodeProperties } from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class CommonSendDataLayout extends BaseLayout {
  constructor(node: Common_SendDataNode) {
    super(node);

    const { properties } = node;
    const {
      frontenSummaryDefinition,
      frontenHtmlFileName,
      frontenBusinessArea,
      frontenCategory,
      frontenDocumentType,
      frontenQueue,
      frontenClaimHandlerId,
      businessArea,
      lineOfBusiness,
      insuredObject,
      customerNumber,
      policyNumber,
      claimNumber,
      claimType,
      parentType,
      claimReportName,
      country,
      customerEmail,
      customerName,
      customerPhone,
      incidentDate,
      stargateTemplate,
      vehicleRegistrationNumber,
      legalNumber,
      bankAccountNumber,
      bankAccountOwner,
      bankAccountOwnerSummaryMessage,
      notBankAccountOwnerSummaryMessage,
      bankAccountNotVerifiedSummaryMessage,
      brand,
      scenario
    } = NodeProperty;

    this.propertiesTab
      .addGroup()
      .addTextField(
        {
          fieldName: frontenSummaryDefinition,
          label: nodeProperties[frontenSummaryDefinition].label,
          placeholder: nodeProperties[frontenSummaryDefinition].placeholder,
          ...properties[frontenSummaryDefinition],
        },
        this.mappedFields
      )

      .addTextField(
        {
          fieldName: frontenHtmlFileName,
          label: nodeProperties[frontenHtmlFileName].label,
          placeholder: nodeProperties[frontenHtmlFileName].placeholder,
          ...properties[frontenHtmlFileName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: frontenBusinessArea,
          label: nodeProperties[frontenBusinessArea].label,
          placeholder: nodeProperties[frontenBusinessArea].placeholder,
          ...properties[frontenBusinessArea],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: frontenCategory,
          label: nodeProperties[frontenCategory].label,
          placeholder: nodeProperties[frontenCategory].placeholder,
          ...properties[frontenCategory],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: frontenDocumentType,
          label: nodeProperties[frontenDocumentType].label,
          placeholder: nodeProperties[frontenDocumentType].placeholder,
          ...properties[frontenDocumentType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: frontenClaimHandlerId,
          label: nodeProperties[frontenClaimHandlerId].label,
          placeholder: nodeProperties[frontenClaimHandlerId].placeholder,
          ...properties[frontenClaimHandlerId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: frontenQueue,
          label: nodeProperties[frontenQueue].label,
          placeholder: nodeProperties[frontenQueue].placeholder,
          ...properties[frontenQueue],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: businessArea,
          label: nodeProperties[businessArea].label,
          placeholder: nodeProperties[businessArea].placeholder,
          ...properties[businessArea],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: lineOfBusiness,
          label: nodeProperties[lineOfBusiness].label,
          placeholder: nodeProperties[lineOfBusiness].placeholder,
          ...properties[lineOfBusiness],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: insuredObject,
          label: nodeProperties[insuredObject].label,
          placeholder: nodeProperties[insuredObject].placeholder,
          ...properties[insuredObject],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: customerNumber,
          label: nodeProperties[customerNumber].label,
          placeholder: nodeProperties[customerNumber].placeholder,
          ...properties[customerNumber],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: policyNumber,
          label: nodeProperties[policyNumber].label,
          placeholder: nodeProperties[policyNumber].placeholder,
          ...properties[policyNumber],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: claimNumber,
          label: nodeProperties[claimNumber].label,
          placeholder: nodeProperties[claimNumber].placeholder,
          ...properties[claimNumber],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: claimType,
          label: nodeProperties[claimType].label,
          placeholder: nodeProperties[claimType].placeholder,
          ...properties[claimType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: parentType,
          label: nodeProperties[parentType].label,
          placeholder: nodeProperties[parentType].placeholder,
          ...properties[parentType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: claimReportName,
          label: nodeProperties[claimReportName].label,
          placeholder: nodeProperties[claimReportName].placeholder,
          ...properties[claimReportName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: country,
          label: nodeProperties[country].label,
          placeholder: nodeProperties[country].placeholder,
          ...properties[country],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: customerEmail,
          label: nodeProperties[customerEmail].label,
          placeholder: nodeProperties[customerEmail].placeholder,
          ...properties[customerEmail],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: customerName,
          label: nodeProperties[customerName].label,
          placeholder: nodeProperties[customerName].placeholder,
          ...properties[customerName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: customerPhone,
          label: nodeProperties[customerPhone].label,
          placeholder: nodeProperties[customerPhone].placeholder,
          ...properties[customerPhone],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: incidentDate,
          label: nodeProperties[incidentDate].label,
          placeholder: nodeProperties[incidentDate].placeholder,
          ...properties[incidentDate],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: stargateTemplate,
          label: nodeProperties[stargateTemplate].label,
          placeholder: nodeProperties[stargateTemplate].placeholder,
          ...properties[stargateTemplate],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: vehicleRegistrationNumber,
          label: nodeProperties[vehicleRegistrationNumber].label,
          placeholder: nodeProperties[vehicleRegistrationNumber].placeholder,
          ...properties[vehicleRegistrationNumber],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: legalNumber,
          label: nodeProperties[legalNumber].label,
          placeholder: nodeProperties[legalNumber].placeholder,
          ...properties[legalNumber],
        },
        this.mappedFields
      ).addTextField(
      {
        fieldName: bankAccountNumber,
        label: nodeProperties[bankAccountNumber].label,
        placeholder: nodeProperties[bankAccountNumber].placeholder,
        ...properties[bankAccountNumber],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: bankAccountOwnerSummaryMessage,
        label: nodeProperties[bankAccountOwnerSummaryMessage].label,
        placeholder: nodeProperties[bankAccountOwnerSummaryMessage].placeholder,
        ...properties[bankAccountOwnerSummaryMessage],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: bankAccountOwner,
        label: nodeProperties[bankAccountOwner].label,
        placeholder: nodeProperties[bankAccountOwner].placeholder,
        ...properties[bankAccountOwner],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: notBankAccountOwnerSummaryMessage,
        label: nodeProperties[notBankAccountOwnerSummaryMessage].label,
        placeholder: nodeProperties[notBankAccountOwnerSummaryMessage].placeholder,
        ...properties[notBankAccountOwnerSummaryMessage],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: bankAccountNotVerifiedSummaryMessage,
        label: nodeProperties[bankAccountNotVerifiedSummaryMessage].label,
        placeholder: nodeProperties[bankAccountNotVerifiedSummaryMessage].placeholder,
        ...properties[bankAccountNotVerifiedSummaryMessage],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: brand,
        label: nodeProperties[brand].label,
        placeholder: nodeProperties[brand].placeholder,
        ...properties[brand],
      },
      this.mappedFields
    ).addTextField(
      {
        fieldName: scenario,
        label: nodeProperties[scenario].label,
        placeholder: nodeProperties[scenario].placeholder,
        ...properties[scenario],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default CommonSendDataLayout;
