/* eslint-disable no-console */
import axios, { AxiosError, CancelToken } from 'axios';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { RequestMethod, ApiResponse } from './types';
import { appInsights } from '@/appInsights/AppInsights';

export const API_PREFIX = '/api';
export const TIMEOUT_IN_MS = 30000;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export const CALL_API_ABORTED = 'Api call aborted';

export interface CallApiParams<T> {
  url: string;
  body?: T;
  method?: RequestMethod;
  cancelToken?: CancelToken;
  timeout?: number;
  baseURL?: string;
}

/**
 * Calls the editor api with the specified request method
 *
 * @template T
 * @template K
 * @param {CallApiParams<K>} {
 *   url,
 *   body,
 *   method,
 *   cancelToken,
 * }
 * @return {*}  {Promise<ApiResponse<T>>}
 */
export const callApi = async <T, K>({
  url,
  body,
  method,
  cancelToken,
  baseURL = API_PREFIX,
  timeout = TIMEOUT_IN_MS,
}: CallApiParams<K>): Promise<ApiResponse<T>> => {
  const data = body ? JSON.stringify(body) : null;

  try {
    return await axios({
      headers,
      baseURL,
      method,
      url,
      data,
      timeout,
      cancelToken,
    });
  } catch (e) {
    const error = e as AxiosError<T>;

    const { Information, Error } = SeverityLevel;

    const isRequestCanceled = axios.isCancel(e);

    const severityLevel = isRequestCanceled ? Information : Error;

    if (appInsights) {
      if (isRequestCanceled) {
        appInsights.trackEvent(
          { name: CALL_API_ABORTED },
          { requestUrl: url, requestBody: body }
        );
      } else {
        appInsights.trackException({
          exception: error,
          severityLevel,
          properties: {
            message: error.message,
            requestUrl: url,
            requestBody: body,
          },
        });
      }
    }

    throw error;
  }
};
