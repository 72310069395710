import { EditorConfiguration, EditorConfigurationKeys } from './types';

const prodConfig: EditorConfiguration = {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]:
    'e844ab1a-c36a-47dd-b3f3-65b8ad6cdc03',
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]:
    '5c6669e00c40062e3b2856e2',
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]:
    'D8F5DDDF-C527-4674-8010-E650D2347C58',
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: 'Atlas Editor UI',
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: 'PRODUCTION',
};

export default prodConfig;
