﻿import {
  BaseNodeProperty,
  InputPhoneNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import InputValidationRadioGroup from '@/components/InputValidationRadioGroup/InputValidationRadioGroup';

import BaseLayout from './BaseLayout';

class InputPhoneLayout extends BaseLayout {
  constructor(node: InputPhoneNode) {
    super(node);

    const { properties } = node;

    const {
      message,
      variableName,
      validationRegex,
      validationMessage,
      validationMaxLengthValue,
    } = BaseNodeProperty;

    const { defaultCountry } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addDropdownField(
        {
          fieldName: defaultCountry,
          label: nodeProperties[defaultCountry].label,
          ...properties[defaultCountry],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addJSXElementField(
        {
          fieldName: validationRegex,
          renderElement: InputValidationRadioGroup,
          ...properties[validationRegex],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: baseNodeProperties[validationMessage].label,
          placeholder: baseNodeProperties[validationMessage].placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      )
      .addTextField({
        fieldName: validationMaxLengthValue,
        label: baseNodeProperties[validationMaxLengthValue].label,
        placeholder: baseNodeProperties[validationMaxLengthValue].placeholder,
        ...properties[validationMaxLengthValue],
      });

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
  }
}

export default InputPhoneLayout;
