import {
  BaseNodeProperty,
  InputCurrencyNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import InputValidationRadioGroup from '@/components/InputValidationRadioGroup/InputValidationRadioGroup';

import BaseLayout from './BaseLayout';

class InputCurrencyLayout extends BaseLayout {
  constructor(node: InputCurrencyNode) {
    super(node);

    const { properties } = node;
    const { defaultCurrency, placeholder } = NodeProperty;
    const { message, variableName, validationMessage, validationRegex } =
      BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab
      .addGroup(nodePropertiesGroups.allowedCurrencies)
      .addDropdownField(
        {
          fieldName: defaultCurrency,
          label: nodeProperties[defaultCurrency].label,
          ...properties[defaultCurrency],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: placeholder,
          label: nodeProperties[placeholder].label,
          placeholder: nodeProperties[placeholder].placeholder,
          ...properties[placeholder],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addJSXElementField(
        {
          fieldName: validationRegex,
          renderElement: InputValidationRadioGroup,
          ...properties[validationRegex],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: baseNodeProperties[validationMessage].label,
          placeholder: baseNodeProperties[validationMessage].placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputCurrencyLayout;
